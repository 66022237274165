<template>
  <b-modal
      id="customMessageModal"
      ref="customMessageModal"
      size="md"
      modal-class="main-modal page-main-block"
      ok-title="Закрыть"
      ok-only
      ok-variant="success modal-ok"
      header-class="text-white border-0"
      header-close-variant="orange"
      :title="title"
      title-class="text-center underlined-muted-title"
      footer-class="border-0"
      hide-footer
      body-class="pt-0"
      centered>
    <div class="row mx-0 col-12 mx-auto px-0 px-md-3" v-if="content">
      <div class="col-12 mx-auto pt-3 pb-2 px-0">
        <p class="text-center">{{content}}</p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'FlashMessages',
  data () {
    return {
      title: '',
      content: null
    }
  },
  computed: {
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  methods: {

  },
  mounted () {
    const _this = this
    this.$root.$on('customMessage', function (args) {
      if (typeof args.title !== 'undefined' && args.title) {
        _this.title = args.title
      } else {
        _this.title = 'Сообшение'
      }
      if (typeof args.content !== 'undefined' && args.content) {
        _this.content = args.content
      } else {
        _this.content = null
      }
    })
  }
}
</script>
<style>

</style>
