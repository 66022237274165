<template>
  <div class="col-12 home-bonuses-container px-0 mb-3">
    <div class="area-title-block">
      <h6 class="block-title py-2 px-4 m-0">
        Победители
      </h6>
    </div>

    <b-tabs class="game-page-tab game-info-area pb-3 px-0 text-center"
            v-if="winners && winners.all && winners.day">
      <b-tab class="col-12">
        <template slot="title" class="mx-0">
          <div class="tab-title py-0 col-12 text-center category-item px-0">
            <button class="btn white-color gray-btn py-2 w-100" name="winners">
              Большие Выигрыши
            </button>
          </div>
        </template>

        <div class="game-tab-body">
          <div class="big-wins" ref="winnersAll">

            <div class="winnersAll-inner sliding">
              <div
                  v-for="(winner, index) in winners.all" v-if="winners.all"
                  :key="'winner_'+index"
                  class="row mx-0 winner py-3 px-2 text-center"
                  :class="[index % 2 ? 'light-bg' : '']">

                <div class="col-4 px-0">
                  <span class="gray-color font-weight-bold">{{ winner.user }}</span>
                </div>

                <div class="col-3 px-0">
                  <a class="cursor-pointer" :title="winner.game_name"
                     @click.prevent.stop="gotoGameView(winner)"
                     v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">

                    <img :src="$config.storageUrl+'/'+winner.picture.replace(/^\/|\/$/g, '')" alt="MagnetBet онлайн Казино"
                         :data-src="$config.storageUrl+'/'+winner.picture.replace(/^\/|\/$/g, '')" class="winner-game-img">
                  </a>
                </div>
                <div class="col-5 px-0">
                  <span class="win-amount"> {{ winner.amount }} Руб </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab class="col-12" active>
        <template slot="title" class="mt-2 mx-0">
          <div class="tab-title  col-12 text-center category-item px-0">
            <button class="btn btn-block red-btn py-2" name="games">
              Сегодня выиграли
            </button>
          </div>
        </template>
        <div class="game-tab-body w-100">
          <div class="today-winners" ref="winnersLast24hours">

            <div class="winnersLast24hours-inner sliding">
              <div
                  v-for="(winner, index) in winners.day" v-if="winners.day"
                  :key="'winner_'+index"
                  class="row mx-0 winner py-3 px-2 text-center"
                  :class="[index % 2 ? 'light-bg' : '']">

                <div class="col-4 px-0">
                  <span class="gray-color font-weight-bold">{{ winner.user }}</span>
                </div>
                <div class="col-3 px-0">
                  <a class="cursor-pointer" :title="winner.game_name"
                     @click.prevent.stop="gotoGameView(winner)"
                     v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">

                    <img :src="$config.storageUrl+'/'+winner.picture.replace(/^\/|\/$/g, '')" alt="MagnetBet онлайн Казино"
                         :data-src="$config.storageUrl+'/'+winner.picture.replace(/^\/|\/$/g, '')" class="winner-game-img">
                  </a>
                </div>
                <div class="col-5 px-0">
                  <span class="win-amount"> {{ winner.amount }} Руб </span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div v-else class="row justify-content-center w-100 color-red mx-0 py-4">
      <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slideIntarvalId: false,
      avilPositionsAll: 0,
      avilPositionsLast24hours: 0,
    }
  },
  computed: {
    winners: {
      get() {
        return this.$store.getters.getHomepageWinnersList
      },
      set(newVal) {
        return newVal
      }
    }
  },
  watch: {
    winners(newValue) {
      this.winners = newValue
    }
  },
  methods: {
    gotoGameView(data) {
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для запуска игры нужно авторизоваться')
      }
      const gameName = data.game_name.split(' ').join('-')

      this.$router.push({
        name: 'LaunchGame',
        params: {
          gameName: gameName,
          gameId: data.game_id,
          game: {
            name: gameName,
            gameId: data.game_id,
            provider: data.provider,
            picture: data.picture
          },
          provider: data.provider,
          isLive: 0,
          restoring: false
        }
      })
    },
  },
}
</script>

<style>
.winner-game-img{
  width: 100px;
  height: 70px;
  border-radius: 5px;
  max-width: 100%;
  object-fit: cover;
}
.win-amount i {
  font-size: 15px;
  font-weight: 100 !important;
}

.winner-list .carousel-caption span{
  font-size: 15px;
}
.fa-spinner{
  font-size: 35px;
}
.winner-info span{
  font-size: 15px;
  color: #9a9a9a;
  user-select: none;
}

@media (max-width: 992px) {
  .winner-info span{
    font-size: 12px;
    color: #9a9a9a;
    user-select: none;
  }
  .win-amount i {
    font-size: 10px;
  }
}
</style>

<style scoped>
.game-tab-body {
  overflow: hidden;
}

.game-info-area {
  max-height: 550px;
  overflow: hidden;
}

.light-bg {
  background-color: #202121;
}

@-webkit-keyframes sliding /* Safari and Chrome */ {
  from {
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-1400px);
    -o-transform: translateY(-1400px);
    transform: translateY(-1400px);
  }
}
@keyframes sliding {
  from {
    -ms-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -ms-transform: translateY(-1400px);
    -moz-transform: translateY(-1400px);
    -webkit-transform: translateY(-1400px);
    -o-transform: translateY(-1400px);
    transform: translateY(-1400px);
  }
}
.sliding {
  min-height: 1150px;
  max-height: 1630px;
  height: 100%;
  -webkit-animation: sliding 20s linear infinite;
  -moz-animation: sliding 20s linear infinite;
  -ms-animation: sliding 20s linear infinite;
  -o-animation: sliding 20s linear infinite;
  animation: sliding 20s linear infinite;
}

@media screen and (max-width: 1024px) {
  @-webkit-keyframes sliding /* Safari and Chrome */ {
    from {
      -webkit-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -webkit-transform: translateY(-700px);
      -o-transform: translateY(-700px);
      transform: translateY(-700px);
    }
  }
  @keyframes sliding {
    from {
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -ms-transform: translateY(-700px);
      -moz-transform: translateY(-700px);
      -webkit-transform: translateY(-700px);
      -o-transform: translateY(-700px);
      transform: translateY(-700px);
    }
  }
}
</style>