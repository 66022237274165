<template>
  <div class="col-12 px-0 narrow-loader">
    <div
      v-if="messageText"
      :class="'alert alert-'+alertType" role="alert"
      v-html="messageText"
    />
    <div
      v-else-if="isLoading"
    >
      <div class="alert">
        <div class="col-12 resp-loader loading">
          <img
            :src="icon" alt="MagnetBet loading icon"
            class="narrow-loader-icon"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NarrowLoader',
  props: ['message'],
  data () {
    return {
      icon: require('@/../public/images/icons/main/dots.svg')
    }
  },
  computed: {
    alertType () {
      return this.message.status === 'ok' ? 'success' : 'danger'
    },
    messageText () {
      if (this.message) {
        if (Array.isArray(this.message.msg)) {
          let msg = ''
          for (let i = 0; i < this.message.msg.length; i++) {
            msg += this.message.msg[i]
            msg += '<br>'
          }
          return msg
        }
        return this.message.msg
      }
      return null
    },
    isLoading () {
      if (!this.message) {
        return false
      }
      return this.message.status === 'loading'
    }
  }
}
</script>

<style>
    .narrow-loader .alert {
        user-select: none !important;
        border: none !important;
        text-align: center;
        margin: 0 auto !important;
        border-radius: 3px;
        padding: 5px 10px !important;
    }
    .narrow-loader .alert-danger {
        background-color: #980019d6 !important;
        font-size: 14px;
        color:#ffffff !important;
        border: 1px solid #980019d6 !important;
    }

    .narrow-loader .alert-primary {
        background-color: rgba(204, 229, 255, 0.07) !important;
        font-size: 16px;
    }

    .narrow-loader .alert-success {
        font-size: 16px;
        background-color: transparent;
        color: #6e9b78 !important;
        border: 1px solid green !important;
    }
    .narrow-loader-icon{
        height: 12px;
    }
</style>
