<template>
  <div>
    <b-modal
        id="superomaticModal"
        ref="superomaticModal"
        title="Выберите деноминацию"
        size="md"
        :hide-footer="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        modal-class="main-modal page-main-block"
        header-class="text-white border-0"
        header-close-variant="orange"
        title-class="text-center underlined-muted-title"
        body-class="pt-0"
        @cancel="goHome"
        @esc="goHome"
        @backdrop="goHome"
        @headerclose="goHome">
      <b-card no-body class="col-12 border-0 text-white bg-transparent">
        <div class="form-group">
          <div class="col-md-12 mx-auto mb-3 text-center px-0">
            <div class="col-12 mx-auto supero-game-info-area mb-3">
              <h3 class="text-center">
                {{ game.name }}
              </h3>
              <img
                  :src="game.picture" :alt="'MagnetBet online Casino ' +game.name "
                  class="supero-game-img">
            </div>
            <div class="col-12 row mx-0 justify-content-center">
              <div
                  v-for="(value, index) in denominations"
                  :key="index" class="radio col-6 col-sm-3 col-lg-3 mx-auto text-left">
                <label :class="denomination === value ? 'selected-denom' : ''" class="denomination-label">
                  <input
                      v-model="denomination" type="radio"
                      :value="value">
                  <span class="denomination-radio-btn"/>
                  <span class="denomination-value">{{ value }}</span>
                </label>
              </div>
            </div>

            <div class="row w-100 mx-0 mt-2 justify-content-center">
              <div class="col-5 col-lg-5 px-0 mx-2 text-right mx-auto text-center">
                <a class="btn gray-btn btn-block" @click="goHome()">Отмена</a>
              </div>
              <div class="col-5 col-lg-5 px-0 mx-0 mx-lg-2 mx-auto ">
                <button
                    name="submit"
                    class="btn red-btn w-100 border-0"
                    @click.prevent="gotoSuperoView()">
                  Играть
                </button>
              </div>
            </div>
            <div class="row w-100 mx-0 mt-3 justify-content-center">
              <span class="denomination-hint">
                Деноминация умножает  баланс игрока
                <br>
                (Пример: если у Вас 700руб, то с 0,1 деноминацией у вас будет 7000 кредитов на игру)
              </span>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'SuperomaticSelecter',
  props: ['game'],
  data() {
    return {
      denomination: 0.1,
      denominations: []
    }
  },
  computed: {
    isMobile() {
      return this.$_config.environment.mobile
    }
  },
  created() {
    this.denominations = this.$store.getters.getMainSettings.denominations
  },
  mounted() {
    this.$refs.superomaticModal.show()
  },
  methods: {
    gotoSuperoView() {
      this.$emit('superoNominationSelected', this.denomination)
      this.$root.$emit('bv::hide::modal', 'superomaticModal')
      return false
    },
    goHome() {
      return this.$router.push({name: 'home'})
    }
  }
}
</script>

<style scoped>
.denomination-hint{
  font-size: 13px;
  color: #cdc8c8;
}
.bg-transparent {
  background-color: transparent;
}

.supero-game-img {
  height: 180px;
}

.denomination-radio-btn {
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #363636;
  margin: auto;
  -webkit-box-shadow: 1px 1px 2px 1px inset #292929 !important;
  box-shadow: 1px 1px 2px 1px inset #292929 !important;
  border-radius: 1px;
}

#superomaticModal input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.selected-denom .denomination-radio-btn {
  background-color: #980019;
  text-align: center;
  font-size: 13px;
  line-height: 21px;
  padding-left: 2px;
}

.selected-denom .denomination-radio-btn::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
}

.denomination-label {
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.denomination-value {
  display: inline-block;
  width: 40px;
  text-align: center;
}
</style>
