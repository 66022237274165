<template>
  <div class="row recover-by-sms-container mx-0">
    <div class="col-12 text-center mx-auto my-2">
      <small class="underlined-muted-title">
        На Ваш номер телефона придет звонок.<br>Код - Последние 4 цифры номера  с которого поступил звонок
      </small>
    </div>
    <b-row class="w-100 mt-3 mx-auto">
      <b-form
        class="col-12 mb-4 mx-auto recover-form px-0"
        @submit.prevent="Restore">
        <b-form-group
          id="phone-input-group" class="mb-4"
          label-for="phone-input">
          <div class="col-12 d-inline-block">
            <label class="col-form-label white-color">Введите номер телефона</label>
          </div>
          <div class="col-12 d-inline-block">
            <the-mask
              id="phone-input"
              v-model="form.phone"
              mask="+#(###)-###-##-##"
              class="form-control main-input"
              :disabled="userData.phone_confirmed === 1? 'disabled' : false"
              required
            />
          </div>
        </b-form-group>

        <b-form-group>
          <div class="col-12 row mx-0 justify-content-between">
            <div class="col-6 col-sm-4 px-0">
              <captcha ref="password_change_sms" :captcha_key="'password_change_sms'" />
            </div>
            <div class="col-6 col-sm-8 px-0">
              <input
                v-model="form.captcha"
                class="form-control main-input"
                required
              >
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке
              </small>
            </div>
          </div>
        </b-form-group>

        <b-row class="w-100 mt-3 mx-auto col-12">
          <alert :message="response" />
        </b-row>

        <div v-if="show" class="col-12 px-0">
          <b-form-group class="mb-2 mb-sm-4">
            <div class="col-12 d-inline-block text-center">
              <label class="col-form-label white-color">Последние 4 цифры номера  с которого поступил звонок</label>
            </div>
            <div class="col-12 d-inline-block">
              <input
                v-model="form.restore_code"
                type="text"
                class="form-control main-input"
                required
              >
            </div>
          </b-form-group>

          <b-form-group class="mb-2 mb-sm-4">
            <div class="col-12 col-sm-4 d-inline-block text-center">
              <label class="col-form-label white-color">Новый пароль</label>
            </div>
            <div class="col-12 col-sm-8 d-inline-block">
              <input
                v-model="form.new_password"
                required
                type="password"
                class="form-control main-input"
              >
            </div>
          </b-form-group>

          <b-form-group class="mb-2 mb-sm-4">
            <div class="col-12 col-sm-4 d-inline-block text-center">
              <label class="col-form-label white-color">Пароль еще раз</label>
            </div>
            <div class="col-12 col-sm-8 d-inline-block">
              <input
                v-model="form.new_password_confirm"
                type="password"
                required
                class="form-control main-input"
              >
            </div>
          </b-form-group>
        </div>

        <b-row class="col-12 mx-0 mt-3">
          <div class="mx-auto">
            <button
                    name="submit"
              class="btn main-btn text-white px-4"
              :disabled="isSubmitting"
            >
              <span v-if="show">Изменить</span>
              <span v-else>Звонить на номер</span>
            </button>
          </div>
        </b-row>
      </b-form>
    </b-row>
  </div>
</template>

<script>
import Captcha from '@/components/main/Captcha'
import Alert from '@/components/Alert'

export default {
  name: 'ChangeViaCall',
  data () {
    return {
      response: null,
      isSubmitting: false,
      form: {
        phone: '',
        captcha: '',
        restore_code: '',
        new_password: '',
        new_password_confirm: ''
      },
      show: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  components: {
    Captcha,
    Alert
  },
  methods: {
    Restore () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      if (this.show) {
        const sendPayload = {
          phone: this.form.phone,
          restore_code: this.form.restore_code,
          new_password: this.form.new_password,
          password_change_captcha: this.form.captcha,
          new_password_confirm: this.form.new_password_confirm
        }

        const queryString = this.$_config.makeQueryStringFromObject(sendPayload)

        this.axios.post(
          this.$_config.baseUrl + '/Api/PasswordChangeRequest/call', queryString,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({ data }) => {
          this.isSubmitting = false
          this.response = data
          if (data.status === 'ok') {
            this.show = false
            this.form.captcha = ''
            this.$refs.password_change_sms.updateCaptcha()
            setTimeout(() => {
              this.$router.push({ name: 'login' });
              this.$root.$emit('bv::hide::modal', 'recoverAccountModal')
            }, 3000)
          }
        }).catch((err) => {
          if (err) {
            this.isSubmitting = false
            this.form.captcha = ''
            this.$refs.password_change_sms.updateCaptcha()
            this.response = { status: 'error', msg: err.response.data.errors }
          }
        })
      } else {
        this.axios.post(
          this.$_config.baseUrl + '/Api/passwordChangeCall',
          `phone=${this.form.phone}&captcha=${this.form.captcha}`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({ data }) => {
          this.isSubmitting = false
          this.response = data
          if (data.status === 'ok') {
            this.show = true
            this.form.captcha = ''
          }
          this.$refs.password_change_sms.updateCaptcha()
        }).catch((err) => {
          if (err) {
            this.isSubmitting = false
            this.$refs.password_change_sms.updateCaptcha()
            this.form.captcha = ''
            this.response = { status: 'error', msg: err.response.data.errors }
          }
        })
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.userData && this.userData.phone) {
        this.form.phone = this.userData.phone
      }
    }, 1500)

  }
}
</script>
