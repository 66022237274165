<template>
  <b-modal
      id="flashMessagesModal"
      size="md"
      title="Системные сообщения"
      hide-footer
      modal-class="main-modal page-main-block"
      header-class="text-white border-0"
      hide-header-close
      title-class="text-center underlined-muted-title"
      body-class="pt-0"
      content-class="border-0">
    <div v-if="flashMessages" class="col-12 px-0 new-messages-area">
      <div
          v-for="(message ,messageIndex) in flashMessages.messages"
          :id="'new_message_'+messageIndex"
          :key="messageIndex"
          class="form-group mb-3 py-2  pl-3 pr-2 message-item white-color">
        <div class="row mx-0 message-text-area w-100">
          <p class="message-text" v-html="message"></p>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 text-right">
      <a
          class="btn mark-new-as-read"
          title="Пометить как прочитанное"
          @click.prevent="unsetFlashMessages">
        <i class="fa fa-envelope"></i> Закрыть
      </a>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'FlashMessages',
  computed: {
    flashMessages () {
      return this.$store.getters.getFlashMessages
    }
  },
  methods: {
    unsetFlashMessages () {
      this.$store.dispatch('unsetFlashMessages')
    }
  },
  mounted () {
    if (this.flashMessages) {
      this.$root.$emit('bv::show::modal', 'flashMessagesModal')
    }
  }
}
</script>
<style>
#flashMessagesModal .message-text{
  width: 100%;
  text-align: center;
  margin-bottom: 0 !important;
}
#flashMessagesModal .message-text-area{
  margin: auto;
}
#flashMessagesModal .message-item{
  min-height: 65px;
  display: flex;
}
</style>
