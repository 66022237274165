<template>
  <b-modal
    id="confirmPhoneModal"
    title="Подтверждение номер телефона"
    size="md"
    modal-class="main-modal page-main-block"
    title-class="text-center underlined-muted-title"
    footer-class="border-0"
    hide-footer
    body-class="pb-2 pt-1 px-3 "
    ok-title="Закрыть"
    ok-variant="success modal-ok"
    header-class="text-white border-0 pb-3"
    centered
  >
    <form v-if="userData"
          class="phone-confirmation-form"
          @submit.prevent="confirmPhone">
      <b-form-group v-if="!confirmCodeSent" class="mb-4">
        <div class="col-12 d-inline-block">
          <label class="col-form-label white-color">Введите номер телефона</label>

        </div>

        <div class="col-12 d-inline-block">
          <the-mask
            id="phone-input"
            v-model="payload.phone"
            mask="+#(###)-###-##-##"
            class="form-control main-modal-input"
            required
          />
          <small> Пожалуйста введите номер начиная с цифры "7" </small>
        </div>
      </b-form-group>

      <b-form-group v-if="!confirmCodeSent">
        <div class="col-12 row mx-0 justify-content-between">
          <div class="col-6 col-sm-4 px-0">
            <captcha ref="phone_confirm_captcha" :captcha_key="'phone_confirm_captcha'" />
          </div>
          <div class="col-6 col-sm-8 px-0">
            <input
              v-model="payload.captcha"
              class="form-control main-modal-input"
              required
            >
            <small class="d-none d-md-block captcha-hint text-muted">
              Введите символы которые видите на картинке
            </small>
          </div>
        </div>
      </b-form-group>

      <b-row class="w-100 mx-auto col-12">
        <alert :message="response" />
      </b-row>

      <div v-if="!confirmCodeSent" class="col-12 mx-0 mt-3">
        <div class="mx-auto">
          <button
            name="submit"
            class="btn red-btn text-white px-4 mx-auto d-flex"
            :disabled="isSubmitting" @click.prevent="callForCode"
          >
            Звонить на номер
          </button>
        </div>
      </div>

      <div v-if="confirmCodeSent" class="col-12 px-0">
        <b-form-group class="mb-2 mb-sm-4">
          <div class="col-12 d-inline-block text-center pl-0">
            <label class="col-form-label white-color">Последние 4 цифры номера  с которого поступил звонок</label>
          </div>
          <div class="col-12 d-inline-block pl-1">
            <input
              v-model="payload.call_code"
              type="text"
              class="form-control main-modal-input"
              required
            >
          </div>
        </b-form-group>
        <b-form-group v-if="confirmCodeSent">
          <div class="col-12 row mx-0 justify-content-between">
            <div class="col-6 col-sm-4 px-0">
              <captcha ref="phone_confirm_captcha" :captcha_key="'phone_confirm_captcha'" />
            </div>
            <div class="col-6 col-sm-8 px-0">
              <input
                v-model="payload.captcha"
                class="form-control main-modal-input"
                required
              >
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке
              </small>
            </div>
          </div>
        </b-form-group>
        <b-row class="w-100 mx-auto col-12">
          <Alert :message="phoneConfirmResponse" />
        </b-row>
        <div class="form-group row w-100">
          <div class="mx-auto">
            <button
              name="submit"
              class="btn main-btn w-100 border-0 d-flex mx-auto px-4 gray-btn"
              :disabled="isFormSubmitting"
            >
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Captcha from '@/components/main/Captcha'
import Alert from '@/components/Alert'

export default {
  name: 'ConfirmPhone',
  data () {
    return {
      confirmCodeSent: false,
      response: null,
      isSubmitting: false,
      isFormSubmitting: false,
      phoneConfirmResponse: null,
      payload: {
        phone: '',
        captcha: '',
        call_code: ''
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  components: {
    Alert,
    Captcha
  },
  methods: {
    callForCode () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      const queryString = `phone=${this.payload.phone}&captcha=${this.payload.captcha}`
      this.axios.post(
        this.$_config.baseUrl + '/Api/callForPhoneConfirmation', queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.response = data
        if (data.status === 'ok') {
          this.confirmCodeSent = true
        }
        this.payload.captcha = ''
        this.isSubmitting = false
        this.$refs.phone_confirm_captcha.updateCaptcha()
      }).catch(() => {
        this.response = {
          status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
        }
        this.payload.captcha = ''
        this.isSubmitting = false
      })
    },
    confirmPhone () {
      this.phoneConfirmResponse = { status: 'loading' }
      this.isFormSubmitting = true
      const queryString = `phone_confirmation_captcha=${this.payload.captcha}&call_code=${this.payload.call_code}`

      this.axios.post(
        this.$_config.baseUrl + '/Api/confirmPhoneAfterCall', queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.phoneConfirmResponse = data
        this.isFormSubmitting = false
        if (data.status === 'ok') {
          this.$store.dispatch('fetchUserData')
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmPhoneModal')
          }, 5000)
        }
      }).catch(() => {
        this.phoneConfirmResponse = { status: 'error', msg: 'Ошибка при отправке запроса, повторите позже' }
        this.isFormSubmitting = false
      })
      this.$refs.phone_confirm_captcha.updateCaptcha()
    }
  },
  mounted () {
    if (this.userData) {
      this.payload.phone = this.userData.phone
    }
  },
  watch: {
    // userData (newValue) {
    //   if (newValue) {
    //     window.location.reload()
    //   }
    // }
  }

}
</script>


<style scoped>

</style>
