<template>
    <b-modal id="confirmSelfieModal" title="Верификация  селфи" size="lg" hide-footer modal-class="main-modal main-block"
        body-class="pb-2 pt-1 px-3 " centered header-class="text-white border-0 pb-0"
        title-class="text-center underlined-muted-title">
        <div class="col-12 px-0 mt-3">
            <h5 class="text-center text-white">
                Уважаемый пользователь! Для надежности и сохранности Вашего аккаунта просим вас пройти Селфи верификацию
            </h5>
        </div>
        <div class="col-12 px-0 py-3">
            <h6 class="text-center text-light">
                <strong class="text-danger">Важно!</strong>
                Сделайте селфи так, что бы обязательно было видно Ваше лицо!
                Минимальный размер изображения 500 кб, максимальный - 5 Мб
            </h6>
            <form v-if="userData" class="passport-confirmation-form" @submit.prevent="sendSelfieForVerification">
                <b-form-group class="mb-3 py-2">
                    <div class="col-12 px-0">
                        <p class="text-center w-100"> Загрузите Селфи</p>
                        <div class="col-12 px-0 row mx-0 justify-content-between passport-area">
                            <div class="col-12 col-md-6 mb-2"
                                v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/main/dots.svg') }">
                                <img v-if="!selfieImage.fileChosen" :data-src="selfieImage.example"
                                    :src="selfieImage.example" alt="Upload verify passport image"
                                    class="verification-passport-example col-9">
                                <img v-else :src="selfieImage.tmp" :data-src="selfieImage.tmp"
                                    alt="Upload verify passport image" class="verification-passport-example col-12">
                            </div>
                            <div class="col-12  col-md-6 d-flex">
                                <b-form-file v-model="payload.selfieFile" @change="onSelfieFileChange" required
                                    class="main-modal-input upload-passport-file-input" type="file" size="md"
                                    :state="selfieImage.uploadAllowed" drop-placeholder="Перетащите файл "
                                    placeholder="Файл не выбран"
                                    :browse-text="selfieImage.fileChosen ? 'Изменить' : 'Выберите файл'">
                                </b-form-file>
                            </div>
                        </div>
                    </div>
                    <b-row class="w-100 mt-1 mx-auto col-12">
                        <alert :message="selfieImage.actionMessage"></alert>
                    </b-row>
                </b-form-group>
                <b-form-group v-if="selfieImage.uploadAllowed" class="verify-captcha-area">
                    <div class="col-12 px-0 row mx-0 justify-content-between">
                        <div class="col-6 col-sm-4 col-lg-3 px-0">
                            <captcha ref="password_confirmation" :captcha_key="'selfie_upload'" />
                        </div>
                        <div class="col-6 col-sm-8 px-0">
                            <input v-model="payload.captcha" class="form-control main-input" required>
                            <small class="d-none d-md-block captcha-hint text-muted">
                                Введите символы которые видите на картинке
                            </small>
                        </div>
                    </div>
                </b-form-group>
            </form>

            <b-row class="w-100 mt-3 mx-auto col-12">
                <alert :message="submitResponse"></alert>
            </b-row>

            <div v-if="selfieImage.uploadAllowed" class="form-group mt-3">
                <button name="submit" class="btn red-btn text-white px-4 mx-auto d-flex" :disabled="isSubmitting"
                    @click.stop.prevent="sendSelfieForVerification">
                    Отправить
                </button>
            </div>
        </div>
    </b-modal>
</template>


<script>

import captcha from '@/components/main/Captcha'
import Alert from '@/components/Alert'
import Config from '@/config'


export default {
    name: 'ConfirmSelfie',
    data() {
        return {
            allowedTypes: [
                'image/png',
                'image/jpeg',
                'image/jpg',
                'image/heif',
                'image/heic',
            ],
            allowedSize: {
                min: 10200,
                max: 5242880
            },
            submitResponse: null,
            isSubmitting: false,
            payload: {
                selfieFile: null,
                captcha: ''
            },
            selfieImage: {
                example: Config.baseUrl + '/static/public/images/identify/selfie.png',
                uploadAllowed: false,
                tmp: null,
                fileChosen: false,
                actionMessage: null
            },
        }
    },
    computed: {
        userData() {
            return this.$store.getters.userData
        },
        isMobile() {
            return this.$_config.environment.mobile
        }
    },
    components: {
        Alert,
        captcha
    },
    methods: {
        onSelfieFileChange(e) {
            const file = e.target.files[0];
            let size = file.size;
            let type = file.type;
            this.selfieImage.actionMessage = { status: 'loading' }
            if (!this.allowedTypes.includes(type)) {
                this.selfieImage.fileChosen = false
                this.selfieImage.uploadAllowed = false
                this.selfieImage.actionMessage = {
                    status: 'error', msg: 'Файлы только png,jpg,heif,heic или jpeg'
                }
                return false
            }

            if (size < this.allowedSize.min || size > this.allowedSize.max) {
                this.selfieImage.fileChosen = false
                this.selfieImage.uploadAllowed = false
                this.selfieImage.actionMessage = {
                    status: 'error', msg: 'Размер файла от 500кб то 5Мб'
                }
                return false
            }
            this.selfieImage.uploadAllowed = true
            this.selfieImage.fileChosen = true
            this.selfieImage.tmp = URL.createObjectURL(file);
            this.selfieImage.actionMessage = null
        },
        sendSelfieForVerification() {
            this.submitResponse = { status: 'loading' }
            this.isSubmitting = true
            let formData = new FormData();
            if (!this.payload.selfieFile) {
                this.isSubmitting = false
                this.submitResponse = {
                    status: 'error', msg: 'Фотографии не выбраны'
                }
                return false
            }

            if (!this.payload.captcha) {
                this.isSubmitting = false
                this.submitResponse = {
                    status: 'error', msg: 'Заполните защитный код'
                }
                return
            }

            formData.append('identifyImageSelfie', this.payload.selfieFile);
            formData.append('captcha', this.payload.captcha);

            this.axios.post(
                this.$_config.baseUrl + '/Api/uploadSelfieImage/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(({ data }) => {
                this.submitResponse = data
                this.payload.captcha = ''
                this.isSubmitting = false
                if (data.status && data.status === 'ok') {
                    setTimeout(() => {
                        this.$root.$emit('bv::hide::modal', 'confirmSelfieModal')
                        this.$root.$emit('changed')
                        window.location.reload()
                    }, 3500)
                } else {
                    this.$refs.password_confirmation.updateCaptcha()
                }
            }).catch(() => {
                this.isSubmitting = false
                this.submitResponse = {
                    status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
                }
            })
        }
    }
}

</script>