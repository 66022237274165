const regs = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^(\d{11}|\d{12})$/,
  login: /^[A-Za-z0-9]{5,15}$/,
  password: /^[A-Za-z0-9]{5,15}$/,
  promo: /^[A-Za-z0-9]{5,8}$/,
  currency: /^(\d{1}|\d{2})$/
}

class Validator {
  install (Vue) {
    Vue.prototype.__validate = (inputType, value) => {
      if (typeof regs[inputType] !== 'undefined') {
        return this.validate(inputType, value)
      }
    }
  }
  validate (inputType, value) {
    return (value === '')? '' : (regs[inputType].test(value)) ? 'has-success' : 'has-error';
  }
}

export default new Validator()
