<template>
  <div class="row recover-by-email-container mx-0">
    <div class="col-12 text-center mx-auto mt-2">
      <small class="underlined-muted-title"> На вашу почту придёт письмо с инструкцией для смены пароля </small>
    </div>
    <b-row class="w-100 mt-2 mx-auto">
      <b-form
        class="col-12 mb-4 mx-auto recover-form px-0"
        @submit.prevent="Restore">
        <b-form-group class="mb-4">
          <div class="col-12 text-left">
            <label class="col-form-label text-white">Введите адрес эл.почты</label>
          </div>
          <div class="col-12">
            <input
              v-model="restoreEmail.email"
              :class="__validate('email', restoreEmail.email)"
              type="email"
              class="main-modal-input form-control"
              required>
            <i class="fa input-hint"></i>
          </div>
        </b-form-group>

        <b-form-group>
          <div class="col-12 row mx-0 justify-content-between">
            <div class="col-6 col-sm-4 px-0">
              <captcha
                ref="pass_change_email_captcha" class="ml-0"
                :captcha_key="'password_restore'"
              />
            </div>
            <div class="col-6 col-sm-8 px-0">
              <input
                v-model="restoreEmail.password_restore_captcha"
                required
                class="form-control main-modal-input"
              >
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке
              </small>
            </div>
          </div>
        </b-form-group>

        <b-row class="w-100 mt-3 mx-auto col-12">
          <alert :message="response" />
        </b-row>

        <b-row class="col-12">
          <div class="mx-auto">
            <button name="submit"
              class="btn red-btn text-white px-4"
              :disabled="isSubmitting">
              Отправить письмо
            </button>
          </div>
        </b-row>
      </b-form>
    </b-row>
  </div>
</template>

<script>
import Captcha from '@/components/main/Captcha'
import Alert from '@/components/Alert'

export default {
  name: 'RecoverViaEmail',
  components: {
    Captcha,
    Alert
  },
  data () {
    return {
      show: false,
      response: null,
      isSubmitting: false,
      restoreEmail: {
        email: '',
        password_restore_captcha: ''
      },
      passChangeEmailResponse: null,
      passChangeEmailSubmitting: false
    }
  },
  methods: {
    Restore () {
      this.response = { status: 'loading' }
      this.isSubmitting = true

      const sendPayload = {
        email: this.restoreEmail.email,
        password_restore_captcha: this.restoreEmail.password_restore_captcha
      }
      const queryString = this.$_config.makeQueryStringFromObject(sendPayload)

      this.axios.post(
        this.$_config.baseUrl + '/Api/restorePasswordRequest/',
          queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          this.show = true
          this.restoreEmail.password_restore_captcha = ''
        }
        this.$refs.pass_change_email_captcha.updateCaptcha()
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = { status: 'error', msg: err.response.data.errors }
        }
      })
    }
  }
}
</script>
