<template>
  <div class="col-12 home-bonuses-container px-0 mb-3" style="display: none">
    <div class="area-title-block">
      <h6 class="block-title py-2 px-4 m-0">
        Бонусы
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeBonuses',
  mounted() {
    this.$store.dispatch('fetchWheelBonus')
  },
  methods: {
    getIcon(icon) {
      return require('@/../public/images/icons/bonuses/' + icon)
    },
    goToBonusPage() {
      return this.$router.push({name: 'bonuses'})
    },
    showInfo() {
      this.$root.$emit('bv::show::modal', 'individualBonusInfo')
    }
  }
}
</script>

<style>
.home-bonuses-container {
  background-color: #333333;
}
</style>
