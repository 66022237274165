<template>
  <div class="row mx-0 slider-container home-carusel h-100">
    <b-carousel :interval="6000" controls indicators ride="true">
      <b-carousel-slide
        v-for="(slide, index) in slides"
        :key="index"
        v-lazy-container="{
          selector: 'img',
          loading: require('@/../public/images/icons/small-loader.svg'),
        }"
      >
        <img
          slot="img"
          class="main-slide-img"
          :data-src="slide"
          :src="slide"
          @click="onSlideClick(index)"
          alt="MagnetBet Casino banner image"
        />
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      slides: {
        newBanner: require("@/../public/images/banner/magnet_new.png"),
        hell: require("@/../public/images/banner/magnet.png"),
        wheel: require("@/../public/images/banner/magnet_wheel.webp"),
        stats: require("@/../public/images/banner/magnet_stats.webp"),
        tg: require("@/../public/images/banner/magnet_tg.webp"),
        slide_7: require("@/../public/images/banner/magnet_games.webp"),
        slide_2: require("@/../public/images/banner/slide_2.webp"),
      },
     
    };
  },
  methods: {
    onSlideClick(index) {
      if (!this.contacts || typeof this.contacts !== 'object') {
        return;
      }

      const tgGroup = this.contacts.hasOwnProperty('tg_group') ? this.contacts.tg_group : '';
      const tgManager = this.contacts.hasOwnProperty('tg_manager') ? this.contacts.tg_manager : '';

      if (index === "tg" && tgGroup) {
        window.open(tgGroup, "_blank");
      }

      if (index === "newBanner" && tgManager) {
        window.open(tgManager, "_blank");
      }
    }
  },
  computed: {
    contacts() {
      return this.$store.getters.getMainSettings.socialLinks
    },
  }
};
</script>

<style>
.main-slide-img {
  max-width: 100vw;
  width: 100vw;
  height: calc(100vw / 4.8);
}

.slider-home-container img[lazy="loading"] {
  object-fit: none !important;
}
.home-carusel .slide .carousel-control-prev,
.home-carusel .slide .carousel-control-next{
   top: unset!important;
   bottom: 20px!important;
   width: 35px;
   height: 35px;
   right: 50px;
   background-color: #ffffff80;
   border-radius: 8px;
   display: none;
  }
  .home-carusel .slide:hover .carousel-control-prev,
  .home-carusel .slide:hover .carousel-control-next{
    padding-top: 7px;
    display: block;
  }
  .home-carusel .slide .carousel-control-prev{
    left: calc(100% - 130px);
  }   
.carousel-indicators li {
  border-radius: 50%;
  height: 8px !important;
  width: 8px !important;
  border: none !important;
  background: #f1f1f1 !important;
  margin-right: 7px !important;
  margin-left: 7px !important;
}

.carousel-indicators li.active {
  background-color: #860019 !important;
}
@media screen and (max-width: 990px) {
  .home-carusel .slide:hover .carousel-control-prev{
    display: none!important;
  }
  .home-carusel .slide:hover .carousel-control-next{
    display: none!important;
  }
}
@media screen and (max-width: 500px) {
  .slider-home-container .carousel-indicators {
    display: none !important;
  }

  .slider-home-container img[lazy="loading"] {
    object-fit: contain !important;
  }
}
</style>
