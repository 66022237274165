<template>
  <div class="py-3 py-lg-5 col-12">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        Главная / <span class="active-router-text">Статистика Выигрышей  </span>
      </h6>
    </div>
    <div class="row mx-0 col-12 col-lg-10 col-xl-8 mx-auto px-0">
      <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
        Статистика Выигрышей
      </h4>

      <div class="col-12 mx-auto text-center my-5 py-3 page-main-block px-0 px-lg-3">
        <div class="col-12 col-sm-12 col-md-12 mt-3 mt-lg-3 mx-auto px-0">
          <b-tabs
              content-class="mt-0 mt-lg-3"
              class="tab account-tab px-0"
              nav-class="col-12 col-lg-8 mx-auto px-0">
            <b-tab class="tab-item col-12 px-0 py-4" title="За 24 часа" active>
              <statistics-item mode="day"></statistics-item>
            </b-tab>
            <b-tab class="tab-item" title="За месяц">
              <statistics-item mode="month"></statistics-item>
            </b-tab>
            <b-tab class="tab-item" title="Самые большие">
              <statistics-item mode="total"></statistics-item>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsItem from "@/components/main/StatisticsItem";

export default {
  components: {
    StatisticsItem
  },
  mounted() {
    this.$store.dispatch('fetchMainStatistics')
    window.scrollTo({
      top: 0
    })
  }
}
</script>
