<template>
  <div class="deposit-container py-4 col-12 col-lg-11 col-xl-10  mx-auto mb-5">
    <div class="col-12 page-router mt-0 mt-lg-4">
      <h6 class="text-white w-100 text-center">
        Главная /
        <span class="active-router-text">Версия Андроид</span>
      </h6>
    </div>
    <h4 class="w-100 text-center text-white my-3 my-lg-5 page-main-title">
      Версия Андроид
    </h4>

    <div class="col-12 mx-auto deposit-main-area px-0">
      <div class="row mx-0">

        <div class="col-12 col-sm-6 col-lg-4 page-main-block mh-500 mb-3">
          <h5 class="text-muted mb-0 mt-3 my-lg-3 text-center">
            <span class="active-router-text"><strong>1. </strong></span>
            <strong class=""> Как установить </strong>
          </h5>

          <div class="col-xs-12 text-center">
            <iframe width="420" height="315" src="https://youtube.com/embed/Ja0aG107WV0?feature=share" class="mt-3 mw-100" frameborder="0" allowfullscreen>
            </iframe>
            <a class="btn download-btn px-5 py-4 mt-3" download :href="$config.baseUrl+'/static/public/application/MagnetBet.apk'">
              Скачать приложение
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 page-main-block mh-500 mb-3">
          <h5 class="text-muted mb-0 mt-3 my-lg-3 text-center">
            <span class="active-router-text"><strong>2. </strong></span>
            <strong class=""> Регистрация</strong>
          </h5>

          <div class="chosen-method-info mt-5 col-12">
            Для использования андроид версии игр необходимо
            <br><br>
            - зарегистрироваться на сайте  <span class="active-router-text"><strong>MAGNET.BET</strong></span>
            <br><br>
            - скачать приложение из раздела "Версия Андроид"
            <br><br>
            - отправить игровой Логин
            <a href="https://t.me/magnetcasinosupport" target="_blank" class="active-router-text"><strong>операторам в техподдержку.</strong></a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 page-main-block mh-500 mb-3">
          <h5 class="text-muted mb-0 mt-3 my-lg-3 text-center">
            <span class="active-router-text"><strong>3. </strong></span>
            <strong class=""> Пополнить счет</strong>
          </h5>

          <div class="chosen-method-info mt-5 col-12">
            Пополнить счет можно путем <br><br>
            1. перевода суммы с вашего основного аккаунта на игровой баланс в скачанном приложении из раздела "Выплаты"
            <br><br>
            2. через
            <a href="https://t.me/magnetcasinosupport" target="_blank" class="active-router-text"><strong>оператора в телеграм.</strong></a>
          </div>

          <b-form class="withdraw-form my-3 col-12">
            <div class="input-group mb-2 mb-lg-4">
              <div class="input-group mb-2">
                <div class="col-12 px-0">
                  <input
                      id="withdraw-amount"
                      v-model="payload.amount"
                      class="form-control main-input"
                      type="text"
                      :min="selectedMethod.min_amount"
                      :max="selectedMethod.max_amount"
                      placeholder="Введите сумму вывода"
                      required>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <Alert :message="formResponse"></Alert>
            </div>

            <b-row class="mt-2 mx-0">
              <div class="col-12 col-lg-12 mx-auto px-0">
                <div class="green-btn-bg">
                  <button
                      name="openModal"
                      class="btn red-btn w-100 text-white"
                      :disabled="isSubmitting"
                      @click.stop.prevent="openModal()">
                    Вывести деньги
                  </button>
                </div>
              </div>
            </b-row>
          </b-form>

          <div class="chosen-method-info mb-3 col-12">
            <span class="hint-amount gray-color">Минимальная сумма выплаты {{selectedMethod.min_amount}} руб.</span><br>
            <span class="hint-amount gray-color">Максимальная сумма выплаты {{ selectedMethod.max_amount }} руб. за один перевод.</span><br>
            <span class="hint-amount gray-color">Вывод происходит в ручном режиме и занимает до 5 минут.</span>

            <br><br>

            <span class="hint-amount gray-color">Для снятия средств обратитесь в службу поддержки Телеграмм
              <a href="https://t.me/vpayfin" target="_blank" class="text-dark-orange"><strong>@vpayfin</strong></a>
            </span>
          </div>

        </div>

      </div>

      <b-modal
          id="confirmWithdrawModal"
          v-model="confirmWithdrawModal"
          size="md"
          modal-class="main-modal"
          :hide-header="true"
          footer-border-variant="gray">
        <div class="col-12 px-0 text-center">
          <span class=""> {{ hint }} </span>

          <div class="row mx-0 my-3">
            <Alert :message="popupMessages"></Alert>
          </div>
        </div>
        <template v-slot:modal-footer="{ ok, cancel}" class="border-0">
          <b-button name="makeWithdraw"
                    size="sm" class="btn red-btn text-white"
                    @click.prevent="makeWithdraw()">
            Вывести
          </b-button>
          <b-button name="cancel"
                    size="sm" class="gray-btn"
                    @click="cancel()">
            Отмена
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
export default {
  name: 'Android',
  data() {
    return {
      selectedMethod: {
        name: 'android',
        min_amount: 100,
        max_amount: 15000,
        type: ''
      },
      payload: {
        amount: '',
      },
      formResponse: {},
      popupMessages: {},
      hint: '',
      isSubmitting: false,
      confirmWithdrawModal: false,
    }
  },
  components: {
    Alert
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },

  },
  methods: {
    openModal() {
      this.formResponse = {}
      this.popupMessages = {}
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }

      if (!this.selectedMethod.name) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста выберите платежную систему'}
        return false
      }

      if (!this.payload.amount || this.payload.amount != parseFloat(this.payload.amount)) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста, укажите правильую сумму'}
        return false
      }

      if (this.payload.amount < this.selectedMethod.min_amount) {
        this.formResponse = {
          status: 'error',
          msg: 'Минимальная сумма для выбранного метода  ' + this.selectedMethod.min_amount
        }
        return false
      }

      if (this.payload.amount > this.selectedMethod.max_amount) {
        this.formResponse = {status: 'error', msg: 'Максимальная сумма для снятия ' + this.selectedMethod.max_amount}
        return false
      }


      this.confirmWithdrawModal = true
      this.hint = 'Подтвердите вывод на сумму ' + this.payload.amount + ' руб.'
    },
    makeWithdraw() {
      if (!this.userData) {
        return
      }
      this.formResponse = {status: 'loading'}
      this.popupMessages = {status: 'loading'}
      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/newWithdraw',
          {
            withdraw_amount: this.payload.amount,
            withdraw_requisites: '',
            method: 'android'
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      ).then(({data}) => {
        if (data.status === 'ok') {
          this.popupMessages = data
          this.formResponse = {}
          this.payload.amount = null
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
          }, 4000)
        } else {
          this.confirmWithdrawModal = false
          this.formResponse = data
        }
        this.isSubmitting = false
      }).catch(err => {
        console.log(err)
      })
      return true
    },
  },
  mounted() {

  }
}
</script>

<style scoped>
.chosen-method-info {
  font-size: 14px;
}
.mh-500 {
  min-height: 500px;
}

.download-btn {
  background-color: #860019;
  color: #ffffff !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  animation: zoom-in-zoom-out 2s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
