<template>
  <div id="footer" class="col-12 px-0 row w-100 mx-0">
    <div class="footer-logo-area col-12 px-0 bg-transparent">
      <div class="logo-content" v-lazy-container="{ selector: 'img', loading: loader }">
        <img :src="logo" :data-src="logo" alt="MagnetBet онлайн Казино" class="footer-logo">
      </div>
    </div>
    <div class="footer-main-area col-12 pt-3">
      <div class="col-12 col-lg-10 col-xl-9 px-0 justify-content-between row mx-auto">
        <div class="col-6 px-0">

          <div class="row w-100">
            <div class="col-sm-4">
              <p class="footer-link-inner">
                <router-link :to="{ name: 'casino' }" class="footer-link">
                  Казино
                </router-link>
              </p>
              <p class="footer-link-inner">
                <router-link :to="{ name: 'live' }" class="footer-link">
                  Live Казино
                </router-link>
              </p>
              <p class="footer-link-inner">
                <router-link :to="{
                  name: 'club',
                }" class="footer-link">
                  VIP Клуб
                </router-link>
              </p>

            </div>
            <div class="col-sm-4">
                <p class="footer-link" @click="showPayment">
                      Кошелек
                </p>
            </div>
            <div class="col-sm-4">
              <p class="footer-link-inner">
                <router-link :to="{ name: 'bonuses' }" class="footer-link">
                  Бонусы
                </router-link>
              </p>
              <p class="footer-link-inner">
                <router-link :to="{ name: 'terms' }" class="footer-link">
                  Условия
                </router-link>
              </p>

            </div>
          </div>
        </div>
        <div class="col-6 px-0">

          <div class="row w-100">
            <div class="col-sm-4">
              <p class="footer-link-inner">
                <router-link :to="{ name: 'PrivacyPolicy' }" class="footer-link">
                  Privacy Policy
                </router-link>
              </p>
              <p class="footer-link-inner">
                <router-link :to="{ name: 'RefundPolicy' }" class="footer-link">
                  Refund Policy
                </router-link>
              </p>

            </div>
            <div class="col-sm-4">
              <p class="footer-link-inner">
                <router-link :to="{ name: 'TermsAndConditions' }" class="footer-link">
                  Terms And Conditions
                </router-link>
              </p>
              <p class="footer-link-inner ticket-link">
                <router-link :to="{ name: 'tickets' }" class="footer-link">
                  Открыть тикет
                </router-link>
              </p>

            </div>
            <div class="col-sm-4">
              <p class="footer-link-inner">
                <router-link :to="{ name: 'mainStatistics' }" class="footer-link">
                  Статистика Выигрышей
                </router-link>
              </p>
              <p class="footer-link-inner">
                <router-link
                  :to="{ name: 'statisticsForGame' }"
                  class="footer-link"
                >
                  Статистика Игр
                </router-link>
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="footer-contacts-area col-12">
      <div class="col-12 col-lg-12 col-xl-12 mx-auto py-4  row justify-content-center justify-content-lg-between px-0">
        <div v-for="(contact, index) in socialLinks" :key="index"
          class="col-12 col-md-4  col-lg-2 footer-contact-item pr-3 row mx-0 justify-content-center pl-0 mb-2 mb-lg-0">
          <div class="col-2 col-lg-2  footer-contact-icon-area p-0">
            <a :href="contact.link" target="_blank" rel="noreferrer"
              class="w-100 d-flex h-100 footer-contact-text text-white text-center"
              v-lazy-container="{ selector: 'img', loading: loader }">
              <img :src="contact.icon" :alt="'MagnetBet Казино : контакт ' + contact.title" :data-src="contact.icon"
                class="mw-100 m-auto">
            </a>
          </div>
          <div class="col-9 col-lg-9 footer-contact-info p-0 pl-2 pl-lg-0">
            <small class="w-100 d-block footer-contact-desc text-muted">{{ contact.title }}</small>
            <a :href="contact.link" target="_blank" rel="noreferrer"
              class="w-100 d-block footer-contact-text text-white fs-14">
              {{ contact.info_text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-rights-area col-12">
      <h6 class="w-100 text-center rights-text  py-2 py-lg-3">
        © 2012-{{ getThisYear }} MagnetBet. Все права защищены.
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      showPaymentModal:false,
      logo: require('../../../public/images/logo/footer_logo_ru.png'),
      loader: require('@/../public/images/icons/game-images-loader.svg'),
      icons: {
        supportTg: require('@/../public/images/icons/support_btn.png')
      },
      contacts: {
        tg_group: {
          title: 'Telegram Канал',
          info_text: '',
          icon: require('@/../public/images/icons/social/telegram_.png')
        },
        tg_manager: {
          title: 'Telegram Менеджер',
          info_text: '',
          icon: require('@/../public/images/icons/social/telegram_manager.png')
        },
        tg_bot: {
          title: 'Telegram Бот',
          info_text: '',
          icon: require('@/../public/images/icons/social/telegram_bot.png')
        },
        email: {
          title: 'Email',
          info_text: '',
          icon: require('@/../public/images/icons/social/email.png')
        },
        vk_manager: {
          title: 'VK',
          info_text: 'Менеджер',
          icon: require('@/../public/images/icons/social/vk.png')
        },
        vk_group: {
          title: 'VK',
          info_text: 'Группа',
          icon: require('@/../public/images/icons/social/vk.png')
        },
        chat_support: {
          title: 'Тех поддержка',
          info_text: 'Тех.поддержка',
          icon: require('@/../public/images/icons/social/finsupport.png')
        }
      }
    }
  },
  computed: {
    getThisYear() {
      return new Date().getFullYear()
    },
    userData() {
      return this.$store.getters.userData
    },
    socialLinks() {
      let links = this.$store.getters.getMainSettings.socialLinks;
      let contactList = {...this.contacts};
      const needToNicknames = ['tg_group', 'tg_manager', 'tg_bot'];

      for (let key in links) {
        if (links.hasOwnProperty(key) && contactList.hasOwnProperty(key)) {
          contactList[key]['link'] = links[key];

          if (needToNicknames.includes(key)) {
            const urlObject = new URL(links[key]);
            contactList[key]['info_text'] = '@' + urlObject.pathname.split('/').pop();
          }

          if (key === 'email') {
            contactList[key]['link'] = 'mailto:' + links[key];
            contactList[key]['info_text'] = links[key];
          }
        }
      }

      for (let key in contactList) {
        if (contactList.hasOwnProperty(key) && !contactList[key].link) {
          delete contactList[key];
        }
      }

      return contactList;
    }
  },
  methods:{
    showPayment(){
      this.showPaymentModal = true;
      this.$root.$emit('bv::show::modal', 'paymentModal')
      this.showPaymentModal = false;
    },
  }
}
</script>

<style>
.fixedSupportBtn {
  position: fixed;
  right: 28px;
  bottom: 30px;
  width: 160px;
}

.footer-logo[lazy='loading'] {
  height: 40px;
  margin: 0 auto;
  display: block;
}

.footer-link-inner {
  margin-bottom: 10px;
}

.footer-link {
  color: #cecece;
  font-size: 14px;
  cursor: pointer;
}

.footer-link:hover,
.footer-link:active,
.footer-link:focus {
  color: #cecece;
  text-decoration: underline !important;
}

.footer-game-provider a {
  width: 100% !important;
  margin: auto;
  background-color: #3c3c3c;
  border-radius: 7px;
  height: 55px !important;
}

.logo-content {
  background-color: #1d1d1d;
}

.footer-contacts-area>div {
  border-top: 1px solid #3e3e3e;
}

.footer-rights-area {
  background-color: #171717 !important;
}

.rights-text {
  color: #545454;
  font-size: 15px;
  user-select: none;
  margin-bottom: 0;
}

#footer>div {
  background-color: #1d1d1d;
  color: #ffffff;
}

.footer-logo {
  max-width: 100%;
  margin-top: -34px;
}

.footer-contact-item {
  opacity: 0.75 !important;
}

.footer-contact-item:hover {
  opacity: 1 !important;
}

@media screen and (max-width: 1480px) {
  .footer-logo {
    max-width: 100%;
    margin-top: -23px;
  }
}

@media screen and (max-width: 800px) {
  .footer-logo {
    object-fit: cover;
    height: 60px;
  }

}
</style>
<style scoped>
.ticket-link .footer-link {
  color: #860019 !important;
  font-weight: bold;
}

.fs-14 {
  font-size: 14px;
}

.fixedSupportBtn {
  animation-name: stretch;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}
</style>
