<template>
  <div class="login-container py-3 py-lg-5  col-12">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        Главная / <span class="active-router-text">Авторизация</span>
      </h6>
    </div>
    <div class="row mx-0 col-12 col-sm-8 col-lg-7 mx-auto px-0">
      <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
        Авторизация
      </h4>

      <div class="col-12 mx-auto py-3 py-lg-5 page-main-block px-0">
        <b-form
          class="login-form py-4"
          @submit.prevent="Login">
          <div class="col-12 col-md-9 col-xl-6 mx-auto mb-3 px-0 px-lg-2">
            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.login"
                    class="form-control auth-input"
                    required
                    type="text"
                    placeholder="Ваш логин"
                    :class="__validate('login', payload.login)">
                  <i class="fa input-hint"></i>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="input-group mb-4">
                <div class="col-12">
                  <input
                    v-model="payload.password"
                    type="password"
                    class="form-control auth-input"
                    required
                    placeholder="Ваш пароль"
                    :class="__validate('password', payload.password)">
                  <i class="fa input-hint"></i>
                </div>
              </div>
            </div>

            <div class="col-12 mb-3">
              <alert :message="response" class="px-3" />
              <div class="clearfix" />
            </div>

            <div class="col-12">
              <div class="col-12 col-lg-9 mx-auto">
                <div class="green-btn-bg">
                  <button
                    name="submit"
                    class="btn auth-btn btn-green w-100 red-btn" :disabled="isSubmitting"
                    @click.prevent="Login">
                    Войти!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>

        <div class="col-12 col-md-9 col-lg-7 mx-auto justify-content-between row px-0 px-lg-2">
          <div class="col-12 col-md-6">
            <div class="col-12 mb-2 pr-0">
              <small>
                <span class="text-muted cursor-pointer" @click="openRecoverModal">
                  Забыли пароль?
                </span>
              </small>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="mx-auto col-12 text-center text-lg-right">
              <router-link class="btn btn-red-to-orange w-100" :to="{ path: '/registration' }">
                Регистрация
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert'

export default {
  name: 'Login',
  components: {
    Alert
  },
  data () {
    return {
      response: null,
      isSubmitting: false,
      payload: {
        login: '',
        password: ''
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    }
  },
  methods: {
    openRecoverModal () {
      return this.$root.$emit('bv::show::modal', 'recoverAccountModal')
    },
    Login () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      this.axios.post(
        this.$_config.baseUrl + '/Api/Login', {
          authorize: this.payload
        }
      ).then(async (userData) => {
        const data = userData.data
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          await this.$store.dispatch('fetchUserData')
          this.response = { status: 'ok', msg: 'Добро пожаловать' }
          this.$store.dispatch('fetchMessages')
          this.$store.dispatch('mainSettings')

          const vUser = this.$store.getters.userData
          if(vUser){
            window.ReactNativeWebView.postMessage(JSON.stringify({
              user: vUser.id,
              type: "fetch",
              message: "Success"
            }))
          }
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = { status: 'error', msg: err.response.data.errors }
        }
      })
    }
  },
  mounted () {
    if (this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  watch: {
    userData () {
      if (this.userData) {
        this.$router.push({ path: '/' })
      }
    }
  }
}
</script>

<style scoped>
  .login-form {
    background-color: transparent !important;
  }
  .login-form .auth-input:autofill,
  .login-form .auth-input:autofill:focus,
  .login-form .auth-input:autofill:hover
  {
    box-shadow: 0 0 0 33px #2c2c2c00 inset !important;
  }
</style>
