<template>
  <div>
    <Header></Header>
    <div id="main-content" class="content-block pb-5">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/main/Header'
import Footer from '@/components/main/Footer'

export default {
name: "MainVersionLoader",
  components: {
    Header,
    Footer,
  },
}
</script>
