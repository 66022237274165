<template>
    <div class="py-3 py-lg-5 col-12">
        <div class="col-12 page-router">
            <h6 class="text-white w-100 text-center">
                Главная / <span class="active-router-text">Live</span>
            </h6>
        </div>
        <div class="justify-content-center text-center mt-3 mt-lg-5">
            <h4 class="w-100 text-center text-white my-3 my-lg-4 page-main-title">
                Live
            </h4>
            <div class="row mx-0" v-if="games">
                <div v-for="(game, index) in games" :key="index" class="col-6 col-md-4 col-lg-3 col-xl-2">
                    <GamePreview :game="game" :img="game.picture" />
                </div>

            </div>
            <div class="row mx-0" v-else>
                <div class="row justify-content-center w-100 color-red mx-0 py-4">
                    <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

import GamePreview from './GamePreview.vue'
export default {
    name: 'LiveGames',
    components: {
        GamePreview
    },
    computed: {
        games() {
            return this.$store.getters.liveGames
        }
    },
    mounted() {
        this.$store.dispatch('fetchLiveGames')
    }
}
</script>
 
