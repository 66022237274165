/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import Config from '@/config'
import router from '@/router'
import store from '@/store'
import Socket from '@/socket'
import Chat from '@/common/chat'
import Validator from '@/common/validator'
import VueAxios from 'vue-axios'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import VueTheMask from 'vue-the-mask'
import VueLazyload from 'vue-lazyload'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/../public/fonts/fontawesome/css/all_f.css'
import '@/../public/styles/main.css'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(Config)
Vue.use(Chat)
Vue.use(VueTheMask)
Vue.use(VueLazyload)
Vue.use(Validator)
Vue.use(VueObserveVisibility)

axios.defaults.withCredentials = true
Vue.config.productionTip = false;


(async () => {
    document.write('<div id="page-wrapper"></div>')

    await store.dispatch('fetchUserData').then(resp => {
        if (resp.userData) {
            store.dispatch('fetchMessages')
        }
    })

    await store.dispatch("getMainSettings")
    await store.dispatch('fetchFlashMessages')
    new Vue({
        el: '#app',
        router,
        store,
        template: '<App/>',
        components: { App },
        render: h => h(App),
        mounted() {
            Vue.use(Socket, store)
            document.getElementById('page-wrapper').remove()
        },
    })
})()
