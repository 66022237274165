<template>
  <div class="closed-tickets-container col-12 mt-3 mx-auto px-0" v-if="closedTickets">
    <b-list-group v-if="closedTickets.length" class="col-12 mx-auto pr-0">

      <b-list-group-item
          v-for="(ticket, idx) in closedTickets" :key="idx"
          class="message-item ticket-list-itm mb-4 white-color" :class="'ticket-list-itm-' + ticket.id"
          @click="openTicketHistory(ticket.id)"
      >
        <b-row>
          <b-col cols="12" class="message-text-container px-1"
                 v-html="getTicketsSubject(ticket.type) + '</br>' + ticket.subject"
          />
          <div class="msg-time col-12 text-right mb-3 ticket-itm">
            <span>
              {{ ticket.created_at }}
              <br>
              Заявку закрыл {{ JSON.parse(ticket.closed_from).type !== 'user' ? 'Administration' : JSON.parse(ticket.closed_from).login }}
            </span>
          </div>
        </b-row>
        <b-row :class="'history-area mx-0 mx-lg-5 history-area-' + ticket.id">

          <b-col v-for="(msg, idx) in ticket.messages" :key="idx" class="msg-item-col px-2 mb-3">
            {{ msg.message }}
            <br>
            <a
                v-if="msg.files"
                :href="msg.files"
                target="_blank"
            >
              <img
                  v-if="msg.files"
                  :src="msg.files"
                  width="100px"
              >
            </a>
            <span class="from-hint">
              {{ msg.created_at }} ({{ JSON.parse(msg.sender).type !== 'user' ? 'Administration' : JSON.parse(msg.sender).login }})
            </span>
          </b-col>

        </b-row>
      </b-list-group-item>
    </b-list-group>

    <div v-else class="text-center">
      <span class="gray-color">У вас пока нет закрытые заявки</span>
    </div>
  </div>

  <div class="closed-tickets-container col-12 mt-3 mx-auto px-0" v-else>
    <div class="alert">
      <div class="col-12 resp-loader loading">
        <img
            :src="icon" alt="MagnetBet loading icon"
            class="narrow-loader-icon"
        >
      </div>
    </div>
  </div>

</template>

<script>
import store from "@/store";

export default {
  data() {
    return {
      currentSelected: null,
      icon: require('@/../public/images/icons/main/dots.svg')
    }
  },
  computed: {
    closedTickets() {
      return this.$store.getters.closedTickets
    },
    ticketTypes() {
      return this.$store.getters.ticketTypes
    },

  },
  methods: {
    openTicketHistory(id) {
      let msgElement = document.querySelector('.history-area-' + id)
      if (msgElement.style.display === 'block') {
        msgElement.style.display = 'none'
        return
      }
      document.querySelectorAll('.history-area').forEach(function (elem) {
        elem.style.display = 'none'
      })
      msgElement.style.display = 'block'
    },
    getTicketsSubject(id) {
      if (!this.ticketTypes) {
        store.dispatch('fetchTicketTypes')
        return id
      }

      let title = '';
      this.ticketTypes.map((item) => {
        if (item.id === id) {
          title = item.name
        }
      })
      return title
    }
  },
  watch: {
    ticketTypes(newVal) {
      this.ticketTypes = newVal
    },

  }
}
</script>

<style scoped>
.msg-item-col {
  font-size: 14px;
  background-color: #3d4042;
  padding: 20px 5px;
  border-radius: 7px;
}

.ticket-itm {
  position: absolute;
  top: 10px;
}

.ticket-list-itm {
  cursor: pointer;
}

.history-area {
  display: none;
  transition: 2s;
  background-color: rgba(204, 234, 255, 0.1) !important;
  padding: 10px 15px;
  border-radius: 7px;
  margin-top: 10px;
}

.from-hint {
  font-size: 11px;
  float: right;
}
@media screen and (max-width: 1500px) {
  .ticket-itm {
    position: unset;
  }
}
</style>