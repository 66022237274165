<template>
  <b-modal
    id="newMessagesModal"
    ref="newMessagesModal"
    :title="newMessages.length > 1 ? 'У вас '+newMessages.length+' непрочитанных сообщения' :'У вас есть непрочитанное сообщение'"
    :size="newMessages.length > 4 ? 'lg' : 'md'"
    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    modal-class="main-modal page-main-block"
    header-class="text-white border-0"
    header-close-variant="orange"
    title-class="text-center underlined-muted-title"
    body-class="pt-0"
    content-class="border-0"
    centered
  >
    <div v-if="newMessagesCount > 1 || closingAllMessages" class="form-group mb-2 px-3 text-right">
      <span class="close-all-messages" @click="markAllAsRead">
        Отметить все как прочтенное
      </span>
    </div>
    <div v-if="newMessages" class="col-12 px-0 new-messages-area">
      <div
        v-for="(message ,messageIndex) in newMessages"
        :id="'new_message_'+messageIndex"
        :key="messageIndex"
        class="form-group mb-3 py-2  pl-3 pr-2 message-item white-color"
      >
        <div class="row mx-0 message-text-area">
          <p class="message-text" v-html="message.message" />
        </div>
        <div
          v-if="(response && newMessageInProcess === messageIndex)"
          class="col-12 p-0 row mx-0 justify-content-end mb-2"
        >
          <div class="col-12 mx-auto px-0">
            <Loader :message="response" />
          </div>
        </div>
        <div class="row justify-content-between mx-auto">
          <div class="msg-time col-8 text-left px-0 mb-2 mt-2">
            <span class="msg-time">Время отправки {{ message.time }}</span>
          </div>
          <div class="col-4 text-right mx-auto">
            <a
              class="btn mark-new-as-read"
              title="Пометить как прочитанное"
              @click.prevent="markAsRead(message, messageIndex)"
            >
              <i class="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Loader from '@/components/NarrowLoader'

export default {
  name: 'NewMessagesPopUp',
  data () {
    return {
      response: null,
      isSubmitting: false,
      newMessageInProcess: 0,
      newMessagesCount: 0,
      closingAllMessages: false
    }
  },
  components: {
    Loader
  },
  computed: {
    newMessages () {
      const newMessages = this.$store.getters.newMessages
      if (newMessages) {
        return newMessages
      }
      return false
    }
  },
  methods: {
    async markAllAsRead () {
      this.closingAllMessages = true
      this.response = { status: 'loading' }
      const sessions = this.newMessages
      if (sessions && sessions.length) {
        for (const i in sessions) {
          if (!sessions.hasOwnProperty(i)) {
            continue
          }
          const message = sessions[i]
          await this.markAsRead(message, parseInt(i))
        }
      }

      this.closingAllMessages = false
      return false
    },
    markAsRead (message, messageIndex) {
      this.newMessageInProcess = messageIndex
      this.isSubmitting = true
      this.response = { status: 'loading' }
      const sessionArea = document.getElementById('new_message_' + messageIndex)
      return new Promise(resolve => {
        this.axios.get(
          this.$_config.markMessageAsReadUrl + message.id
        ).then(({ data }) => {
          if (data.status) {
            if (data.status === 'ok') {
              this.isSubmitting = false
              this.response = { status: 'ok', msg: 'Прочитано' }
              this.newMessagesCount = this.newMessagesCount - 1
              if (this.newMessagesCount < 1) {
                setTimeout(() => {
                  this.$root.$emit('bv::hide::modal', 'newMessagesModal')
                }, 2000)
              } else {
                setTimeout(() => {
                  sessionArea.classList.add('d-none')
                }, 1000)
              }
            } else {
              this.isSubmitting = false
              this.response = { status: 'error', msg: data.msg }
            }
          } else {
            this.isSubmitting = false
            this.response = { status: 'error', msg: 'Что-то пошло не так.Пожалуйста, сообщите об этом в контактную службу' }
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          }
          return setTimeout(resolve, 500)
        })
      })
    }
  },
  watch: {
    newMessages () {
      if (this.newMessages) {
        this.response = null
        this.$root.$emit('bv::show::modal', 'newMessagesModal')
      } else {
        setTimeout(() => {
          return this.$root.$emit('bv::hide::modal', 'newMessagesModal')
        }, 1500)
      }
    }
  },
  mounted () {
    if (this.newMessages) {
      this.response = null
      this.newMessagesCount = this.newMessages.length
      this.$root.$emit('bv::show::modal', 'newMessagesModal')
    }
  }
}
</script>

<style>
    .mark-new-as-read{
        border: 1px solid #8d9398 !important;
        padding: 1px 7px !important;
        color:#8d9398 !important;
        margin-top: 4px;
    }
    .mark-new-as-read:hover{
        border: 1px solid #adadad !important;
        padding: 1px 7px !important;
        color:#ffffff!important;
    }
    .mark-new-as-read:hover > i::before{
        content: "\f2b6";
    }
    #newMessagesModal .close-all-messages{
        color: #949292;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        transition: 0.3s;
    }
    #newMessagesModal .close-all-messages:hover{
        color: #cac8c8;
    }
    #newMessagesModal .new-messages-area{
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    #newMessagesModal .modal-dialog{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    #newMessagesModal .msg-time{
        color: #8d9398;
        user-select: none;
    }
    #newMessagesModal .message-item{
        background-color: rgba(204, 234, 255, 0.1) !important;
        border: unset !important;
        border-radius: 3px !important;
        -webkit-box-shadow: 0 0 3px 1px #000 inset !important;
        box-shadow: 0 0 3px 1px #000 inset !important;
        margin-right: 5px;
    }
    #newMessagesModal .message-text{
        border-bottom: 1px solid #5d5b5b;
        padding-bottom: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 100%;
        font-size: 14px;
    }
    #newMessagesModal .message-text-area~div{
        margin-bottom: 6px;
    }
</style>
