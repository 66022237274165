<template>
  <div id="game-load-content" class="col-12 px-0 game-launch mb-5 mb-lg-0 pt-2">
    <div ref="gameContainer" class="col-12 col-lg-11 col-xl-11 px-0 mt-3 game-load-block no-gutters gameContainer">
      <div class="row mx-0">
        <div class="col-12 col-md-10 mx-auto px-0 game-cross-block">
          <div v-if="!loaded || gameClosed || closingGame" class="row w-100">
            <div class="col-12 game-load-alert d-flex">
              <div v-if="!loaded" class="game-load-content">
                <h6 class="text-center text-white">
                  Идет загрузка игры
                </h6>
                <Loader></Loader>
              </div>
              <div v-if="gameClosed || closingGame" class="game-load-content">
                <h5 class="text-center text-white" v-if="gameClosed">
                  {{ gameClosedMessage }}
                </h5>
                <Loader v-if="closingGame"></Loader>
              </div>
            </div>
          </div>
          <div id="game-response-container" class="px-0 game-response-container text-center">
            <div class="row mx-0 buttons-container p-0">
              <a class="text-white close_game_btn" title="Закрыть игру" @click.prevent="closeGame">
                <span class="game_action_icon" :style="'background-image: url('+gameCloseIcon+')'"></span>
              </a>
            </div>
            <iframe
              id="gameLaunchIframe"
              ref="gameLaunchIframe"
              class="gameLaunchIframe"
              :src="gameLaunchUrl()"
              frameborder="0"
              marginwidth="0"
              marginheight="0"
              allowfullscreen style="max-width:100%">
            </iframe>
            <div class="col-12" v-if="loaded && !isLandscape && !closingGame && !gameClosed">
              Переверните экран
            </div>
          </div>
        </div>
        <div v-if="!isFullScreenMode && !isMobile" class="mx-0 buttons-container p-0 buttons-vertical-container">
          <div class="game-action-area">
            <a
              class="text-white d-none d-md-inline-block" title="Закрыть игру"
              @click.prevent="closeGame">
              <span class="game_action_icon" :style="closingGame ? '' : 'background-image: url('+gameCloseIcon+')'">
                <i v-if="closingGame" class="fa fa-spin fa-spinner"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <SuperomaticSelecter
          v-if="provider === 'superomatic' && !restoring"
          :game="game"
          @superoNominationSelected="superoNominationSelected">
        </SuperomaticSelecter>
      </div>
    </div>
  </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'
import Config from '@/config'
import SuperomaticSelecter from './SuperomaticSelecter'
import Loader from '../Loader'
const secondScrollTo = scroller()

export default {
  name: 'LaunchGameMobile',
  props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'provEncoded'],
  data () {
    return {
      isLandscape: false,
      response: { status: 'loading' },
      gameCloseIcon: require('@/../public/images/icons/close_icon.png'),
      maximizeIcon: require('@/../public/images/icons/maximzie.svg'),
      homeIcon: require('@/../public/images/icons/home_icon.svg'),
      gameBg: '',
      isFullScreenMode: false,
      superomaticNomination: null,
      loaded: false,
      launchUrl: '',
      closingGame: false,
      gameClosed: false,
      gameClosedMessage: ''
    }
  },
  components: {
    SuperomaticSelecter,
    Loader
  },
  computed: {
    isMobile () {
      return this.$_config.environment.mobile
    },
    userData () {
      return this.$store.getters.userData
    },
    games () {
      let allGames = this.$store.getters.games

      let topGames = []
      if (typeof allGames.allGames !== 'undefined') {
        const games = allGames.allGames.games
        const tabGamesLength = games.length
        let rndStart = tabGamesLength * Math.random() << 0
        if (rndStart > tabGamesLength - 10) {
          rndStart = tabGamesLength - 10
        }
        topGames = games.slice(rndStart, rndStart + 10)
      }
      return topGames
    },
    winners () {
      return this.$store.getters.getGameWinner
    },
    gameCloseUrl () {
      return Config.baseUrl + '/Api/CloseGame/' + this.provider
    }
  },
  methods: {
    gameLaunchUrl () {
      if (!this.provider || typeof this.provider === 'undefined' || typeof this.gameId === 'undefined') {
        this.$root.$emit('modalAlertMessage', { msg: 'Что-то пошло не так. Попробуйте снова', status: 'error' })
        return ''
      }
      if (this.provider !== 'superomatic') {
        const gameLaunchUrl = [
          Config.baseUrl,
          '/Api/LoadGame/',
          this.provider,
          '/',
          this.gameId ? this.gameId : ''
        ].join('')
        this.launchUrl = gameLaunchUrl
        return gameLaunchUrl
      } else {
        if (!this.superomaticNomination && !this.restoring) {
          this.$root.$emit('bv::show::modal', 'superomaticModal')
          return ''
        } else {
          this.launchUrl = [Config.baseUrl,
            '/Api/LoadGame/',
            this.provider,
            '/',
            this.gameId ? this.gameId : '',
            '?gamelaunchData%5Bnomination%5D=' + this.superomaticNomination
          ].join('')
          return [
            Config.baseUrl,
            '/Api/LoadGame/',
            this.provider,
            '/',
            this.gameId ? this.gameId : '',
            '?gamelaunchData%5Bnomination%5D=' + this.superomaticNomination
          ].join('')
        }
      }
    },
    superoNominationSelected (value) {
      this.superomaticNomination = value
      this.loaded = true
    },
    getImageUrl (game) {
      return Config.baseUrl + '/' + game.picture
    },
    containerSize () {
      try {
        const availWidth = document.querySelector('.game-cross-block').offsetWidth
        const koef = 1.8
        const availHeight = availWidth / koef
        const sizes = {
          width: availWidth,
          height: availHeight
        }
        return sizes
      } catch (err) {
        console.log(err.message)
      }
    },
    screenToggle () {
      if (document.fullscreenElement) {
        this.closeFullscreen()
      } else {
        this.fullscreen()
      }
    },
    goHome () {
      return this.$router.push({ name: 'home' })
    },
    fullscreen () {
      const framesDiv = document.querySelector('#game-response-container')
      if (document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.mozFullScreenEnabled ||
                    document.msFullscreenEnabled) {
        if (framesDiv.requestFullscreen) {
          framesDiv.requestFullscreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        } else if (framesDiv.webkitRequestFullscreen) {
          framesDiv.webkitRequestFullscreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        } else if (framesDiv.mozRequestFullScreen) {
          framesDiv.mozRequestFullScreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        } else if (framesDiv.msRequestFullscreen) {
          framesDiv.msRequestFullscreen()
          framesDiv.classList.add('game-maximized')
          this.isFullScreenMode = true
        }
        if (framesDiv.webkitExitFullscreen) {
          framesDiv.webkitExitFullscreen()
        }
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.isFullScreenMode = false
    },
    setIframeSizes () {
      let container = document.querySelector('.game-cross-block')
      if (!container) {
        return
      }
      let sizes = {
        width: container.offsetWidth,
        height: container.offsetWidth / 1.5,
        maxHeight: screen.availHeight,
        buttonsTop:0,
        frameTop:0
      }
      if(this.provider === "alexa") {
        sizes = {
          width: parseInt(container.offsetWidth),
          height: window.innerHeight,
          maxHeight: screen.availHeight,
          buttonsTop:0,
          frameTop:0
        }
      }
      if (this.isFullScreenMode) {
        container = document.querySelector('#game-response-container')
        sizes = {
          width: container.offsetWidth,
          height: window.innerHeight,
          maxHeight: screen.availHeight,
          buttonsTop:0,
          frameTop:0
        }
      }

      if (this.isMobile) {
        if (this.isLandscape) {
          sizes = {
            width: parseInt(container.offsetWidth),
            height: window.innerHeight,
            maxHeight: screen.availHeight,
            buttonsTop:10,
            frameTop:0
          }
        } else {
          sizes = {
            width: parseInt(container.offsetWidth),
            height: window.innerHeight - 100,
            maxHeight: screen.availHeight,
            buttonsTop:0,
            frameTop:40
          }
        }
      }

      document.querySelector('.gameLaunchIframe').style.height = sizes.height + 'px'
      document.querySelector('.gameLaunchIframe').style.maxHeight = sizes.maxHeight + 'px'
      document.querySelector('.gameLaunchIframe').style.width = sizes.width + 'px'
      document.querySelector('.gameLaunchIframe').style.maxWidth = sizes.width + 'px'
      document.querySelector('.gameLaunchIframe').style.marginTop = sizes.frameTop + 'px'

      document.querySelector('#game-response-container').style.width = sizes.width + 'px'
      document.querySelector('#game-response-container').style.maxWidth = sizes.width + 'px'
      document.querySelector('#game-response-container').style.height = sizes.height + 'px'
      document.querySelector('#game-response-container').style.maxHeight = sizes.maxHeight + 'px'
      document.querySelector('.buttons-container').style.top = sizes.buttonsTop + 'px'
    },
    setOrientationOnChangeEvent () {
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.isLandscape = window.innerWidth > window.innerHeight
          this.setIframeSizes()
        }, 20)
      })
    },
    closeGame () {
      this.closingGame = true
      const url = this.gameCloseUrl
      this.axios.get(
        url
      ).then((answer) => {
        this.$refs.gameLaunchIframe.setAttribute('src', '')
        if (answer.data) {
          const data = answer.data
          this.gameClosed = true
          if (data.status) {
            const message = data.msg
            if (data.status === 'error') {
              this.$root.$emit('modalAlertMessage', { msg: message, status: data.status })
              this.$root.$emit('bv::show::modal', 'modalAlert')
            }
            this.$store.dispatch('fetchFlashMessages')
            this.$store.dispatch('checkForAliveGame')
            setTimeout(() => {
              this.gameClosedMessage = message
              this.closingGame = false
              setTimeout(() => {
                return window.location = ""
                // return this.$router.push({ name: 'home' })
              }, 500)
            }, 1000)
          } else {
            this.closingGame = false
            this.$store.dispatch('fetchFlashMessages')
            this.$store.dispatch('checkForAliveGame')
            this.$root.$emit('modalAlertMessage', { msg: 'Произошла ошибка.Обратитесь к техподдержке', status: 'error' })
            this.$root.$emit('bv::show::modal', 'modalAlert')
            return this.$router.push({ name: 'home' })
          }
        } else {
          this.gameClosed = true
          this.closingGame = false
          this.$store.dispatch('fetchFlashMessages')
          this.$store.dispatch('checkForAliveGame')
          this.$root.$emit('modalAlertMessage', { msg: 'Произошла ошибка.Обратитесь к техподдержке', status: 'error' })
          this.$root.$emit('bv::show::modal', 'modalAlert')
          return this.$router.push({ name: 'home' })
        }
      }).catch(err => {
        if (err) {
          this.closingGame = false
          this.$root.$emit('modalAlertMessage', { msg: err.message(), status: 'error' })
          this.$root.$emit('bv::show::modal', 'modalAlert')
          this.$store.dispatch('checkForAliveGame')
          this.$store.dispatch('fetchFlashMessages')
          return this.$router.push({ name: 'home' })
        }
      })
    }
  },
  mounted () {
    if (!this.userData) {
      return this.$router.push({ name: 'login' })
    }
    window.scrollTo(0,1);
    document.title = this.gameName+' : казино VipMagnit'
    if (typeof this.provider === 'undefined' || typeof this.gameId === 'undefined') {
      this.$root.$emit('modalAlertMessage', { msg: 'Что-то пошло не так. Попробуйте снова', status: 'error' })
      return this.$root.$emit('bv::show::modal', 'modalAlert')
    }
    this.isLandscape = window.innerWidth > window.innerHeight
    this.setOrientationOnChangeEvent()
    this.setIframeSizes()
    if (typeof document.querySelector('.gameLaunchIframe') !== 'undefined' &&
                document.querySelector('.gameLaunchIframe')) {
      document.querySelector('.gameLaunchIframe').onload = () => {
        this.loaded = true
      }
    }

    const gamesLoadParams = {
      provider: this.provider,
      gameId: this.gameId
    }
    this.$store.dispatch('fetchGameWinners', gamesLoadParams)

    setTimeout(() => {
      secondScrollTo('#game-load-content')
    }, 600)

    const fullScreenBtn = document.querySelector('#fullScreenBtn')

    document.addEventListener('webkitfullscreenchange', this.fullscreenChange)
    document.addEventListener('mozfullscreenchange', this.fullscreenChange)
    document.addEventListener('fullscreenchange', this.fullscreenChange)
    document.addEventListener('MSFullscreenChange', this.fullscreenChange)
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        fullScreenBtn.innerHTML = '<i class="fa fa-window-minimize"></i>'
      } else {
        fullScreenBtn.innerHTML = '<span class="game_action_icon" style="background-image: url(' + this.maximizeIcon + ')"></span>'
      }
    })

    if (document.getElementById('game-response-container')) {
      document.getElementById('game-response-container').addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          fullScreenBtn.innerHTML = '<span class="game_action_icon" style="background-image: url(' + this.maximizeIcon + ')"></span>'
        } else {
          const frame = document.querySelector('#game-response-container')
          frame.style.height = this.containerSize().height + 'px'
          fullScreenBtn.innerHTML = '<span class="game_action_icon" style="background-image: url(' + this.maximizeIcon + ')"></span>'
        }
      })
    }
  },
  watch: {
    isLandscape () {
      this.isLandscape = screen.height < screen.width
    },
    restoring () {
      if (this.restoring) {
        this.$root.$emit('bv::hide::modal', 'activeAliveGameModal')
      }
    }
  }
}
</script>

<style>
  #header{
    position: relative;
  }
  .close_game_btn{
    display: flex;
    width: 40px;
    height: 40px;
    margin: auto;
  }
  .supero-game-img{
    height: 120px;
  }
  .content-block{
    margin-top: 0 !important;
  }
  .game_action_icon .fa-spinner{
    color:#ffffff;
    font-size: 17px;
  }
  .game-launch .game-load-content{
    width: 100%;
    height: 200px;
    margin: auto;
  }
  #game-reload-icon>i{
    color: #808080;
    font-size: 20px;
    cursor: pointer;
  }
  .buttons-vertical-container{
    position: absolute;
    right: -25px;
    top: 0;
    min-height: 200px;
    width: 40px;
  }
  .game-action-area{
    background-color: #151515;
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    text-align: center;
    vertical-align: center;
    display: flex;
  }
  .game-action-area a{
    height: 18px;
    margin: auto;
    display: flex;
  }
  .index-area{
    display: flex;
    height: 100%;
  }
  #WheelGameContainer {
    max-height: 100% !important;
  }


  .game_action_icon{
    width: 17px;
    height: 17px;
    background-size: 100% 100%;
    display: inline-block;
    cursor: pointer;
    margin: auto;
  }
  .winner,
  .game-title {
    font-size: 14px;
  }
  .gameLaunchIframe {
    height: 100%;
  }

  .enableFullScreen {
    background-color: transparent !important;
    border: none !important;
  }
  .game-page-tab .tab-pane {
    padding: 0 !important;
  }

  .game-page-tab .nav-tabs {
    border: none !important;
    justify-content: center !important;
  }

  .game-page-tab .nav-link.active {
    background-color: transparent !important;
    border: none !important;
  }

  .game-page-tab .nav-link {
    border: none !important;
    padding: 0 !important;
  }

  .game-page-tab .nav-item {
    width: 50%;
  }

  .game-page-tab .nav-link.active .game-tab-btn {
    background: rgb(254, 138, 17) !important;
    background: linear-gradient(0deg, rgba(254, 138, 17, 1) 3%, rgba(235, 96, 16, 1) 52%, rgba(216, 51, 15, 1) 96%) !important;
  }
</style>

<style scoped>
  #gameLaunchIframe,
  #game-response-container {
    min-height: 500px !important;
    max-height: unset !important;
    height: 100% !important;
  }
  .buttons-container{
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #070a11;
    height: 40px;
  }
  .game-launch{
    padding: 0 !important;
    margin: 0 !important;
  }
  .gameLaunchIframe {
    width: 100%;
    min-height: 500px !important;
    max-height: unset !important;
    height: 100% !important;
  }

  .close-btn {
    background: rgb(254, 138, 17) !important;
    background: linear-gradient(0deg, rgba(254, 138, 17, 1) 3%, rgba(235, 96, 16, 1) 52%, rgba(216, 51, 15, 1) 96%) !important;
    border-radius: 20px;
  }

  .gameContainer {
    height: 100%;
  }

  .tab-icon {
    height: 35px;
    width: auto;
  }

  .tab-title-area {
    border-top: 1px solid #10131c;
    border-bottom: 1px solid #10131c;
    color: #565759;
  }

  .game-info-area .tab-content {
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
  }

  .game-info-area::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }

  .game-info-area::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 7px;
  }

  .light-bg {
    background-color: #202121;
  }

  .game {
    cursor: pointer;
    height: 85px;
  }

  .list-index {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
  }

  .game-img {
    border-radius: 3px;
    height: auto;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .game-rating-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .winners-title {
    font-size: 12px;
  }

  .game-tab-btn:hover {
    box-shadow: 0 0 20px rgba(207, 83, 27, 0.6) !important;
  }

  .game-info-area {
    background-color: rgba(11, 14, 21, 0.6);
    overflow-x: hidden;
    position: relative;
  }

  .game-tab-btn {
    width: 100%;
    background: #1b2026 !important;
    border: none !important;
    border-radius: 20px;
  }

  .game-load-block {
    width: 100%;
    min-height: fit-content !important;
    background-repeat: no-repeat;
    height: fit-content;
    margin: 0 auto !important;
  }

  .game-launch .game-load-block .game-load-alert {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
    text-align: center;
    min-height: 200px;
    height: 100%;
    background-color: #070a11;
  }

  .game-launch .game-load-block .game-load-alert .alert {
    min-height: 200px;
    align-items: center !important;
    justify-content: center;
    display: flex !important;
    padding: 0 !important;
  }

  #app.mobile .page-content.main-block {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .resp-loader.loading {
    max-width: 300px;
    max-height: 300px;
    top: unset;
    left: unset;
  }

  .landscape-close-btn {
    top: 10px;
  }
  #goHomeBtn .game_action_icon{
    width: 20px;
  }
</style>
