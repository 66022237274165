<template>
  <div class="col-12 px-0">
    <div
      v-if="alertMessage"
      :class="'alert alert-'+alertType" role="alert"
      v-html="alertMessage"
    />
    <div v-else-if="isLoading">
      <div class="alert alert-primary">
        <div class="col-12 resp-loader loading">
          <i class="fa fa-spinner fa-spin fa-fw" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: ['message'],
  computed: {
    alertType () {
      return this.message.status === 'ok' ? 'success' : 'danger'
    },
    alertMessage () {
      if (this.message) {
        if (Array.isArray(this.message.msg)) {
          let msg = ''
          for (let i = 0; i < this.message.msg.length; i++) {
            msg += this.message.msg[i]
            msg += '<br>'
          }
          return msg
        }
        return this.message.msg
      }
      return null
    },
    isLoading () {
      if (!this.message) {
        return false
      }
      return this.message.status === 'loading'
    }
  }
}
</script>

<style>
    .alert {
        border: none !important;
        text-align: center;
        margin: 10px auto;
        border-radius: 3px;
    }
    .alert-danger {
        font-size: 16px;
        color:#ffffff !important;
        background-color: #980019d6 !important;
        border: none !important;
        border-radius: 3px;
        box-shadow: 0 0 4px 3px inset #580614d6;
    }

    .alert-primary {
        background-color: rgba(204, 229, 255, 0.07) !important;
        font-size: 16px;
    }

    .alert-success {
        font-size: 16px;
        background-color: rgba(50, 138, 71, 0.1) !important;
        color: #6e9b78 !important;
    }

</style>
