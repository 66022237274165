<template>
  <b-modal
    id="modalAlert"
    ref="modalAlert"
    size="md"
    modal-class="main-modal page-main-block"
    ok-title="Закрыть"
    ok-only
    ok-variant="success modal-ok"
    header-class="text-white border-0"
    header-close-variant="orange"
    :title="reason"
    title-class="text-center underlined-muted-title"
    footer-class="border-0"
    hide-footer
    body-class="pt-0"
    centered
  >
    <div class="row mx-0 col-12 mx-auto">
      <div class="col-12 mx-auto py-3 px-0">
        <div class="col-12 px-0">
          <div
            v-if="alertMessage"
            :class="'alert alert-'+alertType" role="alert"
            v-html="alertMessage"
          />
          <div v-else-if="isLoading">
            <div class="alert alert-primary">
              <div class="col-12 resp-loader loading">
                <i class="fa fa-spinner fa-spin fa-fw" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalAlert',
  data () {
    return {
      reason: '',
      message: null
    }
  },
  computed: {
    alertType () {
      return this.message.status === 'ok' ? 'success' : 'danger'
    },
    alertMessage () {
      if (this.message) {
        if (Array.isArray(this.message.msg)) {
          let msg = ''
          for (let i = 0; i < this.message.msg.length; i++) {
            msg += this.message.msg[i]
            msg += '<br>'
          }
          return msg
        }
        return this.message.msg
      }
      return null
    },
    isLoading () {
      if (!this.message) {
        return false
      }
      return this.message.status === 'loading'
    }
  },
  created () {
    const _this = this
    this.$root.$on('modalAlertMessage', function (value) {
      _this.message = value
      if (value.status === 'ok') {
        _this.reason = 'Сообщения'
      } else {
        _this.reason = 'Произошла ошибка'
      }
    })
  },
  mounted () {
    const _this = this
    this.$root.$on('modalAlertMessage', function (value) {
      _this.message = value
      if (value.status === 'ok') {
        _this.reason = 'Сообщения'
      } else {
        _this.reason = 'Произошла ошибка'
      }
    })
  }
}
</script>

<style>
    .alert {
        border: none !important;
        text-align: center;
        margin: 10px auto;
        border-radius: 3px;
    }
    #modalAlert .alert-danger {
        background-color: #980019d6 !important;
        font-size: 14px;
        color:#ffffff !important;
        border: 1px solid #980019d6 !important;
    }

    .alert-primary {
        background-color: rgba(204, 229, 255, 0.07) !important;
        font-size: 16px;
    }

    .alert-success {
        font-size: 16px;
        background-color: rgba(50, 138, 71, 0.1) !important;
        color: #6e9b78 !important;
    }
    #modalAlert .modal-header .close{
        margin: 0 !important;
    }
</style>
