<template>
  <div class="opened-tickets-container col-12 mt-3 mx-auto px-0" v-if="openedTickets">
    <b-list-group v-if="openedTickets.length" class="col-12 mx-auto pr-0">

      <b-list-group-item
          v-for="(ticket, idx) in openedTickets" :key="idx"
          class="message-item ticket-list-itm mb-4 white-color" :class="'ticket-list-itm-' + ticket.id"
      >

        <b-col @click="openTicketHistory($event, ticket.id)">
          <b-row>
            <b-col cols="12" class="message-text-container px-1"
                   v-html="getTicketsSubject(ticket.type) + '</br>' + ticket.subject"
            />
            <div class="msg-time col-12 text-right mb-3 ticket-itm">
            <span>
              {{ ticket.created_at }}
            </span>
            </div>
          </b-row>
          <b-row :class="'history-area mx-0 mx-lg-5 history-area-' + ticket.id">

            <b-col v-for="(msg, idx) in ticket.messages" :key="idx" class="msg-item-col px-2 mb-3">
              {{ msg.message }}
              <br>
              <a
                  v-if="msg.files"
                  :href="msg.files"
                  target="_blank"
              >
                  <img
                      v-if="msg.files"
                      :src="msg.files"
                      width="100px"
                  >
              </a>

              <span class="from-hint">
              {{
                  msg.created_at
                }} ({{ JSON.parse(msg.sender).type !== 'user' ? 'Administration' : JSON.parse(msg.sender).login }})
            </span>
            </b-col>
          </b-row>
        </b-col>

        <b-row :class="'reply-form-area mx-0 mx-lg-5 form-area-' + ticket.id">
          <b-form
              class="create-ticket-form py-4 w-100"
              @submit.prevent="replyTicket(ticket.id)">
            <div class="col-12 mb-3">

              <div class="col-12">
                <div class="input-group mb-4">
                  <div class="col-12">
              <textarea
                  v-model="payload.message"
                  class="form-control auth-input comment-area"
                  required
                  placeholder="Сообщение *">
              </textarea>
                    <i class="fa input-hint"></i>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="input-group mb-4">
                  <div class="col-12">
                    <b-form-file
                        v-model="payload.ticketFiles"
                        type="file"
                        class="form-control auth-input comment-area"
                    >
                    </b-form-file>
                    <i class="fa input-hint"></i>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="col-12 row mx-0 justify-content-between">
                  <div class="col-6 col-sm-3 col-lg-2 px-0">
                    <captcha ref="reply_ticket_captcha" :captcha_key="'reply_ticket_captcha'"
                             :class="'captcha_' + ticket.id"/>
                  </div>
                  <div class="col-6 col-sm-9 col-lg-10 px-0">
                    <input
                        v-model="payload.captcha"
                        class="form-control auth-input"
                        required
                    >
                    <small class="d-none d-md-block captcha-hint text-muted">
                      Введите символы которые видите на картинке *
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-3">
                <alert :message="response" class="px-3"/>
                <div class="clearfix"/>
              </div>

              <div class="col-12">
                <div class="col-12 col-lg-9 mx-auto">
                  <div class="green-btn-bg">
                    <button
                        name="submit"
                        class="btn auth-btn btn-green w-100 red-btn" :disabled="isSubmitting"
                        @click.prevent="replyTicket(ticket.id)">
                      Отправить
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4">
                <div class="col-12 col-lg-9 mx-auto">
                  <div class="red-btn">
                    <button class="btn auth-btn btn-red w-100 red-btn" :disabled="isSubmitting"
                            @click.prevent="closeTicketModal(ticket.id)">
                      Закрыть заявку
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </b-form>
        </b-row>

        <b-modal id="confirmTicketDeletion"
                 ref="confirmTicketDeletion"
                 size="lg"
                 modal-class="main-modal main-block"
                 header-class="text-white border-0"
                 header-close-variant="orange"
                 title-class="text-center underlined-muted-title"
                 body-class="pt-0"
                 :hide-header="true"
                 :hide-footer="true">
          <h3 class="text-center my-4">Закрыть заявку ? </h3>
          <div class="col-12 px-0 text-center pb-5 pb-lg-0  ">

              <button class="btn btn-danger font-weight-bold col-5 mr-4" @click="closeTicket()">
                Да
              </button>
              <button class="btn btn-success font-weight-bold col-5" @click="$bvModal.hide('confirmTicketDeletion')">
                Нет
              </button>
          </div>
        </b-modal>

      </b-list-group-item>
    </b-list-group>

    <div v-else class="text-center">
      <span class="gray-color">У вас пока нет заявок</span>
    </div>
  </div>

  <div class="opened-tickets-container col-12 mt-3 mx-auto px-0" v-else>
    <div class="alert">
      <div class="col-12 resp-loader loading">
        <img
            :src="icon" alt="MagnetBet loading icon"
            class="narrow-loader-icon"
        >
      </div>
    </div>
  </div>

</template>

<script>
import store from "@/store";
import Alert from '@/components/Alert'
import Captcha from "@/components/main/Captcha";

export default {
  data() {
    return {
      icon: require('@/../public/images/icons/main/dots.svg'),
      currentSelected: null,
      response: null,
      isSubmitting: false,
      payload: {
        message: '',
        captcha: '',
        ticketFiles: null
      }
    }
  },
  computed: {
    openedTickets() {
      return this.$store.getters.openTickets
    },
    ticketTypes() {
      return this.$store.getters.ticketTypes
    },

  },
  methods: {
    openTicketHistory(event, id) {
      let msgElement = document.querySelector('.history-area-' + id)
      let formElement = document.querySelector('.form-area-' + id)

      if (msgElement.style.display === 'block') {
        msgElement.style.display = 'none'
        formElement.style.display = 'none'
        return
      }
      document.querySelectorAll('.history-area').forEach(function (elem) {
        elem.style.display = 'none'
      })

      document.querySelectorAll('.reply-form-area').forEach(function (elem) {
        elem.style.display = 'none'
      })

      msgElement.style.display = 'block'
      formElement.style.display = 'block'
    },
    getTicketsSubject(id) {
      if (!this.ticketTypes) {
        store.dispatch('fetchTicketTypes')
        return id
      }

      let title = '';
      this.ticketTypes.map((item) => {
        if (item.id === id) {
          title = item.name
        }
      })
      return title
    },
    replyTicket(id) {
      this.payload.ticketId = id
      this.response = {status: 'loading'}
      this.isSubmitting = true

      let formData = new FormData();
      if(this.payload.ticketFiles != null) {
        formData.append('ticketFiles', this.payload.ticketFiles);
      }
      formData.append('ticketId', this.payload.ticketId);
      formData.append('message', this.payload.message);
      formData.append('captcha', this.payload.captcha);

      this.axios.post(
          this.$_config.baseUrl + '/Api/replyTicket/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then((replyData) => {
        const data = replyData.data
        this.isSubmitting = false
        this.payload.captcha = ''
        this.response = data
        if (data.status === 'ok') {
          this.$store.dispatch('fetchTickets')
          this.response = {status: 'ok', msg: 'Отправлено'}
          this.payload.message = ''
          this.payload.ticketFiles = null
        }
        document.querySelector('.captcha_' + id + ' .refresh-btn').click()
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = {status: 'error', msg: err.response.data.errors}
        }
        this.payload.captcha = ''
        document.querySelector('.captcha_' + id + ' .refresh-btn').click()
      })
    },
    closeTicket() {
      this.response = {status: 'loading'}
      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/closeTicket', {
            ticket: this.currentSelected
          }
      ).then((closeData) => {
        const data = closeData.data
        this.isSubmitting = false
        this.payload.captcha = ''
        this.response = data

        if (data.status === 'ok') {
          this.$store.dispatch('fetchTickets')
          this.response = {status: 'ok', msg: 'Заявление успешно закрыт'}
        }
        document.querySelector('.captcha_' + this.currentSelected + ' .refresh-btn').click()
        return this.$root.$emit('bv::hide::modal', 'confirmTicketDeletion')
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = {status: 'error', msg: err.response.data.errors}
        }
        this.payload.captcha = ''
        document.querySelector('.captcha_' + this.currentSelected + ' .refresh-btn').click()
      })
    },
    closeTicketModal(id) {
      this.currentSelected = id
      return this.$root.$emit('bv::show::modal', 'confirmTicketDeletion')
    }
  },
  watch: {
    ticketTypes(newVal) {
      this.ticketTypes = newVal
    }
  },
  components: {
    Captcha,
    Alert
  }
}
</script>

<style scoped>
.msg-item-col {
  font-size: 14px;
  background-color: #3d4042;
  padding: 20px 5px;
  border-radius: 7px;
}

.ticket-itm {
  position: absolute;
  top: 10px;
}

.ticket-list-itm {
  cursor: pointer;
}

.reply-form-area {
  display: none;
}

.history-area {
  display: none;
  transition: 2s;
  background-color: rgba(204, 234, 255, 0.1) !important;
  padding: 10px 15px;
  border-radius: 7px;
  margin-top: 10px;
}

.from-hint {
  font-size: 11px;
  float: right;
}

.comment-area:focus {
  box-shadow: none;
}

@media screen and (max-width: 1500px) {
  .ticket-itm {
    position: unset;
  }
}
</style>