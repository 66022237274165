<template>
  <div>
    <div class="row mx-0 wheel-countdown-container justify-content-center"
      v-if="ready && toNextSpin !== false">
      <div class="row mx-0" v-if="!elapsed">
        <p class="col-12 text-center">До прокрута осталось</p>
        <FlipCountdown :showDays="false" :deadline="asDate" :labels="labels" @timeElapsed="elapsed = true">
        </FlipCountdown>
      </div>
      <div class="row mx-0 justify-content-center w-100" v-else>
        <router-link class="w-100 text-center" :to="{
          name: 'LaunchGame',
          params: {
            gameName: 'WheelOfFortune',
            gameId: 1,
            provider: 'WheelOfFortune'
          }
        }">
          <img :src="wheelImg" :data-src="wheelImg" alt="Magnet.bet - Вам доступно Колесо"
            class="main-bonus-image wheel-img-pulse" :style="whellPage ? 'max-height:250px' : ''">

          <p class="col-12 text-center mt-2 text-white">Вам доступно Колесо Фортуны</p>
          <span class="btn auth-btn red-btn w-100  spin-wheel-btn">Крутить</span>
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="row mx-0 justify-content-center">
         <div class="col-12 text-center">
          <img :src="wheelImg" :data-src="wheelImg" alt="Magnet.bet - Вам доступно Колесо"
            class="main-bonus-image wheel-img-pulse" :style="whellPage ? 'max-height:250px' : ''">
          </div>

        <div v-if="authorized">
          <p class="col-12 text-center">Вам не доступно Колесо Фортуны</p>
          <div class="wheel-progress">
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="'width:' + wheelcondition['percent'] + '%'">
                <span class="popOver" data-toggle="tooltip" data-placement="center"
                  :title="wheelcondition['percent'] + '%'">{{ wheelcondition['percent'] }}%</span>
              </div>
            </div>
          </div>
          <p v-if="wheelcondition && wheelcondition['percent'] < 100" class="col-12 text-center mt-2"
            style="font-size: 13px">У Вас - {{ wheelcondition['last_month_replenishment'] }} /
            {{ wheelcondition['profitBorder'] }}</p>
          <button class="buttonForMore col-12 text-center" @click="infoForWheel()">Подробнее</button>
        </div>

        <p class="col-12 text-center" v-else>Для доступа к Колесу Фортуны пожалуйста пройдите авторизацию</p>
      </div>
    </div>

    <b-modal id="wheelInfo" ref="wheelInfo" size="md" modal-class="main-modal page-main-block" ok-title="Закрыть" ok-only
      ok-variant="success modal-ok" header-class="text-white border-0" header-close-variant="orange"
      title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <span class="text-center">
          Установи приложение на Android и получи ежедневный доступ к "Колесу Фортуны". При выявлении дубликатов, аккаунты
          будут заблокированы. <br>
          <a class="btn download-btn px-5 py-2 mt-3 mx-auto" style="background: #941b1e; color: white" download
            :href="$config.baseUrl + '/static/public/application/MagnetBetV6.apk'">
            Скачать приложение
          </a>
        </span>
      </div>
    </b-modal>

    <b-modal id="info-for-wheel" ref="info-for-wheel" size="md" modal-class="main-modal page-main-block"
      ok-title="Закрыть" ok-only ok-variant="success modal-ok" header-class="text-white border-0"
      header-close-variant="orange" title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer
      body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto">
        <div class="col-12 mx-auto py-3 px-0">
          <div class="col-12 px-0">
            <div class="col-12 mx-auto pt-3 pb-2 px-0">
              <p class="text-center">
                Колесо автоматически активируется при пополнении от 10.000р в месяц
                <br>
                Например: Если вы пополнили с {{ new Date().toLocaleDateString() }} до {{ this.tomorrow }} определенную
                сумму в течении месяца, колесо автоматически активируется.
                <br>
                (месяц считается после текущего дня)
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


  </div>
</template>

<script>
// vue2-flip-countdown
import FlipCountdown from 'vue2-flip-countdown'

export default {
  name: "WheelCountDown",
  data() {
    return {
      wheelImg: require('@/../public/images/bonuses/site_wheel.png'),
      elapsed: false,
      timeToNextSpin: 0,
      asDate: null,
      ready: false,
      labels: {
        minutes: 'Минут',
        hours: 'Часов',
        seconds: 'Секунд'
      }
    }
  },
  computed: {
    toNextSpin() {
      return this.$store.getters.getMainSettings['timeToWheelNextSpin']
    },
    wheelcondition() {
      return this.$store.getters.userData['wheelcondition']
    },
    authorized() {
      return this.$store.getters['userData']
    },
    whellPage(){
      if(this.$route.name === 'LaunchGame'){
        return true;
      }  
      return false;
    }

  },
  methods: {
    showInfo() {
      this.$root.$emit('bv::show::modal', 'wheelInfo')
    },
    infoForWheel() {
      this.$root.$emit('bv::show::modal', 'info-for-wheel')
    },
  },
  created() {
    const tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1)
    this.tomorrow = tomorrow.toLocaleDateString()
  },
  mounted() {
    this.timeToNextSpin = this.toNextSpin
    this.ready = true
    if (this.timeToNextSpin <= 0) {
      this.ready = true
      this.elapsed = true
      return
    }
    this.asDate = new Date(Date.now() + this.timeToNextSpin * 1000).toString()
    this.ready = true
  },
  components: {
    FlipCountdown
  }
}
</script>

<style>
.buttonForMore {
  background-color: #81031a;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 10px;
}

.wheel-progress .progress-bar {
  position: unset;
  border-radius: 50px;
  background: #81031a;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  min-width: 1px !important;
}

.wheel-progress .tooltip {
  float: right;
  z-index: 10;
  top: 0 !important;
  left: unset !important;
  display: block;
  width: 100%;
  text-align: center;
}

.wheel-progress .tooltip>.tooltip-inner {
  background-color: transparent;
  padding: 0;
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  max-width: unset;
}

.wheel-progress .popOver+.tooltip>.tooltip-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000000;
  display: none;
}

.wheel-progress .progress {
  position: relative;
  border-radius: 50px !important;
  overflow: hidden;
  background: #a0a0a0;
  background: -moz-linear-gradient(top, #a0a0a0 0%, #898989 36%, #666666 63%, #545454 100%);
  background: -webkit-linear-gradient(top, #a0a0a0 0%, #898989 36%, #666666 63%, #545454 100%);
  background: linear-gradient(to bottom, #a0a0a0 0%, #898989 36%, #666666 63%, #545454 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a0a0a0', endColorstr='#545454', GradientType=0);
  padding: 0;
  width: 100%;
  max-width: 400px;
  margin-bottom: 0;
  height: 17px;
  margin: auto;
}
</style>


<style scoped>
.spin-wheel-btn {
  animation-name: stretch;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}

.main-bonus-image {
  height: 100%;
  max-height: 150px;
  width: auto;
}

.wheel-img-pulse {
  animation-name: spin;
  animation-duration: 8s;
  animation-timing-function: cubic-bezier(0, 0, 0.74, 1.24);
  animation-iteration-count: infinite;
  animation-play-state: running;
  -webkit-animation-name: spin;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 0.74, 1.24);
  -webkit-animation-duration: 8s;
  -webkit-animation-play-state: running;
  margin-bottom: 5px;
}


@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>