<template>
  <b-modal
    id="ActivatePromoCode"
    v-model="show"
    size="md"
    title="Активируйте промокод"
    title-class="text-muted"
    :hide-footer="true"
    modal-class="main-modal page-main-block"
    header-border-variant="dark"
    @hide="emptyResponse"
  >
    <div class="col-12 px-0">
      <b-row class="no-gutters">
        <b-col class="px-0 mx-0 mx-auto">
          <b-form
            class="form-horizontal px-0 px-lg-3 mx-0"
            @submit.prevent="activatePromocode"
          >
            <b-form-group class="mb-4">
              <div class="col-12 px-0 text-left">
                <label class="col-form-label text-muted">Введите промокод</label>
              </div>
              <div class="col-12 px-0">
                <b-form-input
                  v-model="payload.promocode"
                  required
                  class="main-modal-input"
                  placeholder="Промо код"
                />
              </div>
            </b-form-group>

            <div class="row mx-0 justify-content-between">
              <div class="col-4 px-0">
                <captcha
                  ref="captcha_promocode_activation"
                  :captcha_key="'promocode_activation'"
                  class=""
                />
              </div>
              <div class="col-8 px-0">
                <b-form-input
                  v-model="payload.captcha"
                  required
                  class="main-modal-input"
                  placeholder="Капча"
                />
                <small class="d-none d-md-block captcha-hint text-muted">
                  Введите символы которые видите на картинке
                </small>
              </div>
            </div>

            <alert :message="response" />
            <div class="form-group row mt-5 mx-0">
              <div class="col-6 col-md-6 text-center text-lg-right mt-md-0 px-2">
                <a class="btn gray-btn mx-auto w-100" @click="closePopup">Отмена</a>
              </div>
              <div class="col-6 col-md-6 px-0 px-md-2">
                <div class="">
                  <button
                    class="btn red-btn w-100"
                    name="submit"
                    :disabled="isSubmitting"
                  >
                    Активировать
                  </button>
                </div>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import Alert from '@/components/Alert'
import Captcha from '@/components/main/Captcha'

export default {
  name: 'ActivatePromoCode',
  data () {
    return {
      show: false,
      response: null,
      isSubmitting: false,
      payload: {
        promocode: '',
        captcha: ''
      }
    }
  },
  components: {
    Captcha,
    Alert
  },
  methods: {
    emptyResponse () {
      this.response = null
    },
    closePopup () {
      this.response = null
      this.payload = {
        promocode: '',
        captcha: ''
      }
      this.show = false
    },
    activatePromocode () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      this.axios.post(
        this.$_config.baseUrl + '/Api/ActivateMyPromoCode/',
        `promocode=${this.payload.promocode}&promocode_activation_captcha=${this.payload.captcha}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.response = data
        this.isSubmitting = false
        this.payload.promocode = ''
        this.payload.captcha = ''
        if (data.status !== 'error') {
          setTimeout(() => {
            this.show = false
          }, 3000)
        }
        this.$refs.captcha_promocode_activation.updateCaptcha()
      }).catch(err => {
        if (err) {
          this.$refs.captcha_promocode_activation.updateCaptcha()
          this.response = { status: 'error', msg: err.msg }
          this.isSubmitting = false
        }
      })
    }
  }
}
</script>

<style>
    .main-modal-input{
        background-color: transparent !important;
        border: 1px solid #6c757d !important;
        border-radius: 3px !important;
        color:#ffffff !important;
        font-size: 14px !important;
    }
</style>
