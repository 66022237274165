<template>
  <div class="terms-container pt-0 col-12  mx-auto">
    <div class="row mx-0">
      <div class="col-12 description p-2 p-md-5 ">
        <div class="col-12 mx-auto page-main-block px-0">
          <div v-if="terms == null" class="row justify-content-center w-100 color-red mx-0 py-4">
            <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
          </div>
          <div
                  v-for="(termItem, index) in terms" v-else
                  :key="index" class="col-12 term-area-item"
          >
            <div class="col-12 col-lg-8 mx-auto px-0 term-title-block mb-4">
              <p class="term-title">
              <span class="term-title-number">
                {{ termItem.title.item }}.
              </span>
                <span>{{ termItem.title.text }}</span>
              </p>
            </div>
            <div class="col-12 col-lg-11 mx-auto term-texts-area mb-5 px-0">
              <p
                      v-for="(term, idx) in termItem.paragraphs" v-if="termItem.paragraphs"
                      :key="idx" class="term-text-item"
              >
              <span class="term-number">
                {{ term.item }}
              </span>
                <span class="term-text" v-html="term.text"></span>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RegisterTerms',
  computed: {
    terms () {
      let terms = this.$store.getters.terms;
      if (!terms) {
        this.$store.dispatch('fetchTerms')
      }

      return this.$store.getters.terms
    }
  }
}
</script>

<style scoped>
    .description {
        background-color: rgba(62, 101, 129, 0.2);
        border-radius: 10px;
    }

    .description span {
        line-height: 2;
        font-size: 14px;
        color: white;
    }
</style>
