<template>
  <b-modal
    id="changePasswordModal"
    header-border-variant="dark"
    title="Изменение пароля"
    size="md"
    modal-class="main-modal page-main-block"
    header-class="text-white border-0 pb-0"
    title-class="text-center underlined-muted-title"
    footer-class="border-0"
    hide-footer
    body-class="pb-2 pt-1 px-3"
    centered
  >
    <div v-if="userData" class="recover-account-area col-12 px-0 py-2">
      <div class="col-12 mt-2 px-0 mx-auto">
        <b-tabs content-class="" class="tab pass-recover-tab px-0">
          <b-tab class="tab-item" active>
            <template v-slot:title>
              <span v-if="isMobile">
                <i class="fa fa-envelope" />
              </span>
              <span v-else>По Е-mail</span>
            </template>
            <change-by-email />
          </b-tab>
          <b-tab class="tab-item">
            <template v-slot:title>
              <span v-if="isMobile">
                <i class="fa fa-phone-square" />
              </span>
              <span v-else>По номера телефона</span>
            </template>
            <change-via-call />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ChangeByEmail from './ChangeViaEmail'
// import ChangeBySms from './ChangeViaSms'
import ChangeViaCall from './ChangeViaCall';

export default {
  name: 'ChangePassword',
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  components: {
    ChangeViaCall,
    ChangeByEmail,
    // ChangeBySms
  },
  data () {
    return {
      isSubmitting: false,
      isEmailCodeSent: false,
      isPhoneCodeSent: false,
      emailSentResponse: null,
      smsSentResponse: null,
      passChangeEmailResponse: null,
      passChangePhoneResponse: null,
      passChangeEmailSubmitting: false,
      passChangePhoneSubmitting: false,
      restoreEmailPayload: {
        email: '',
        captcha: '',
        email_sent_code: '',
        new_password: '',
        new_password_confirm: ''
      },
      restoreSmsPayload: {
        phone: '',
        captcha: '',
        phone_sent_code: '',
        new_password: '',
        new_password_confirm: ''
      }
    }
  },
  methods: {
    sendEmailCode () {
      if (!this.userData.email.length || !this.restoreEmailPayload.captcha.length) {
        this.emailSentResponse = { status: 'error', msg: 'Заполните поля почты и капчи' }
        return 0
      }
      this.emailSentResponse = { status: 'loading' }
      this.axios.post(
        this.$_config.passwordChangeEmailPath,
        `email=${this.restoreEmailPayload.email}&captcha=${this.restoreEmailPayload.captcha}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.emailSentResponse = data
        if (data.status === 'ok') {
          this.isEmailCodeSent = true
        }
        this.restoreEmailPayload.captcha = ''
      })
      this.$refs.pass_change_email_captcha.updateCaptcha()
    },
    sendSmsCode () {
      if (!this.restoreSmsPayload.phone.length || !this.restoreSmsPayload.captcha.length) {
        this.smsSentResponse = { status: 'error', msg: 'Заполните все поля' }
        return 0
      }
      this.smsSentResponse = { status: 'loading' }
      this.axios.post(
        this.$_config.passwordChangeSmsPath,
        `phone=${this.restoreSmsPayload.phone}&captcha=${this.restoreSmsPayload.captcha}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        if (data.status === 'ok') {
          this.isPhoneCodeSent = true
        }
        this.smsSentResponse = data
        this.restoreSmsPayload.captcha = ''
      }).catch(err => {
        console.log(err)
      })

      setTimeout(() => {
        this.$refs.password_change_sms.updateCaptcha()
      }, 1000)
    },
    changePasswordWithEmail () {
      this.passChangeEmailResponse = { status: 'loading' }
      this.passChangeEmailSubmitting = true
      const sendPayload = {
        new_password: this.restoreEmailPayload.new_password,
        new_password_confirm: this.restoreEmailPayload.new_password_confirm,
        restore_code: this.restoreEmailPayload.email_sent_code,
        password_change_captcha: this.restoreEmailPayload.captcha
      }
      const queryString = this.$_config.makeQueryStringFromObject(sendPayload)
      this.axios.post(
        `${this.$_config.passwordChangeRequests.mail}`,
        queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.passChangeEmailResponse = data
        this.passChangeEmailSubmitting = false
        this.restoreEmailPayload.captcha = ''
        if (data.status === 'ok') {
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
          }, 3000)
        }
      }).catch(err => {
        if (err) {
          this.passChangeEmailResponse = {
            status: 'error',
            msg: 'Ошибка при отправке запроса, попробуйте снова.'
          }
        }
        this.passChangeEmailSubmitting = false
        this.restoreEmailPayload.captcha = ''
      })
      this.$refs.pass_change_email_captcha.updateCaptcha()
    },
    changePasswordWithSms () {
      this.passChangePhoneResponse = { status: 'loading' }
      this.passChangePhoneSubmitting = true
      const sendPayload = {
        new_password: this.restoreSmsPayload.new_password,
        new_password_confirm: this.restoreSmsPayload.new_password_confirm,
        restore_code: this.restoreSmsPayload.phone_sent_code,
        password_change_captcha: this.restoreSmsPayload.captcha
      }
      const queryString = this.$_config.makeQueryStringFromObject(sendPayload)
      this.axios.post(
        `${this.$_config.passwordChangeRequests.sms}`,
        queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.passChangePhoneResponse = data
        this.passChangePhoneSubmitting = true
        if (data.status === 'ok') {
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
          }, 3000)
        }
      }).catch(err => {
        if (err) {
          this.passChangePhoneResponse = {
            status: 'error',
            msg: 'Ошибка при отправке запроса, попробуйте снова.'
          }
        }
        this.passChangePhoneSubmitting = true
      })
      this.$refs.password_change_sms.updateCaptcha()
    }
  },
  mounted () {
    if (this.userData) {
      this.restoreEmailPayload.email = this.userData.email
      this.restoreSmsPayload.phone = this.userData.phone
    }
  }
}
</script>

<style>
    #changePasswordModal .nav-tabs{
        border-bottom: unset;
    }

    #changePasswordModal .tab-content{
        background-color: #505050;
        border-top: unset;
    }
    #changePasswordModal .nav-item{
        width: 50%;
        text-align: center;
        margin-bottom: 0;
        border-radius: unset;
    }
    #changePasswordModal .modal-dialog{
        background-color: transparent;
    }
    #changePasswordModal .nav-link{
        color:#848484;
        border-radius: unset;
        padding: 15px 20px;
        border: 1px solid #505050;
    }
    #changePasswordModal .nav-link:hover{
        color:#9a9898;
    }
    #changePasswordModal .nav-link.active{
        background-color: #505050;
        border :unset;
        height: 100%;
        color: #aba9a9;
    }
    #changePasswordModal .modal-header .close {
        margin: 0 !important;
    }
    #changePasswordModal .modal-body {
        padding-left: 0;
        padding-right: 0;
    }
</style>

<style scoped>
</style>
