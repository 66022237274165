<template>
  <div class="messages-container col-12 mt-3 mt-lg-5 mx-auto px-3">
    <b-list-group v-if="messages.length" class="col-12 mx-auto pr-0">
      <b-list-group-item
        v-for="(message, idx) in messages" :key="idx"
        class="message-item mb-4 white-color"
      >
        <b-row>
          <b-col
            cols="12" class="message-text-container px-1"
            v-html="message.message"
          />
          <div class="msg-time col-12 text-right mb-3">
            <span>{{ message.time }}</span>
          </div>
          <button
                  name="submit"
            v-if="!message.viewed"
            :ref="'markAsRead['+message.id+']'"
            class="btn btn-sm btn-custom markAsReadBtn red-color position-absolute pr-3"
            :disabled="markAsReads === message.id"
            @click.prevent="()=>{markAsRead(message.id)}"
          >
            Отметить как прочитанное
          </button>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="text-center">
      <span class="gray-color">У вас пока нет сообщений</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Messages',
  data () {
    return {
      message: {},
      markAsReads: null
    }
  },
  computed: {
    messages () {
      if (this.$store.getters.messages !== null) {
        const msgs = Object.values(this.$store.getters.messages)
        return msgs.reverse()
      }
      return {}
    },
    userData () {
      return this.$store.getters.userData
    }
  },
  methods: {
    markAsRead (id) {
      this.markAsReads = id
      this.axios.get(
        this.$_config.baseUrl + '/Api/MessageViewed/' + id
      ).then(() => {
        this.markAsReads = null
        this.$store.dispatch('fetchMessages')
      }).catch(err => {
        if (err) {
          this.$store.dispatch('fetchMessages')
        }
        this.markAsReads = null
      })
    }
  }
}
</script>

<style>
    .message-item {
        background-color: rgba(204, 234, 255, 0.1) !important;
        border: unset !important;
        border-radius: 3px !important;
        box-shadow: 0 0 3px 1px #000 inset !important;
    }
    .msg-time {
        font-size: 11px;
    }
    .message-text-container{
        font-size: 15px;
    }
    .markAsReadBtn {
        right: 0;
        bottom: 0;
    }
</style>
